import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Formulario from './Formulario';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Box, Button, Typography } from '@material-ui/core';
import { EXTERNAL_API_PATHS } from 'utils/constants';

function App({ dataJson, idPlantilla, idTramite,nameFile, reqDataTramite }) {
  const [template, setTemplate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [templates, setTemplates] = useState([]);
  const [formData, setFormData] = useState({});

  // Fetch projects from API
  useEffect(() => {
    axios
      .get(`${EXTERNAL_API_PATHS.plantillaDocumentos}/proyectos`)
      .then((response) => {
        setProjects(response.data);
      })
      .catch((error) => {
        console.error('Error fetching projects:', error);
      });
  }, []);

  // Fetch templates when a project is selected
  useEffect(() => {
    if (selectedProject) {
      const project = projects.find((p) => p.codigo === selectedProject);
      if (project) {
        setTemplates(project.plantilla_documentos);
      }
    }
  }, [selectedProject, projects]);

  useEffect(() => {
    if(reqDataTramite){
      setFormData(JSON.parse(reqDataTramite));
    }else{
      setFormData(mapJsonToFormat(dataJson));
    }
    
  }, []);

  // Fetch template document
  useEffect(() => {
    if (idPlantilla) {
      axios
        .get(`${EXTERNAL_API_PATHS.plantillaDocumentos}/${idPlantilla}`)
        .then(async (responsePlantilla) => {
          const response = await axios.get(
            `/api/files/${responsePlantilla.data.url_documento}`,
            { responseType: 'blob' }
          );
          if (response.status === 200) {
            setTemplate(response.data);
            console.log('Plantilla Cargada!');
          } else {
            console.error('Error en la descarga del archivo:', response.statusText);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [idPlantilla]);

  const findRecordById = (array, id) => {
    if (!array || !Array.isArray(array)) {
      console.error("El primer parámetro debe ser un array.");
      return null;
    }
  
    const record = array.find(item => item.id === id);
  
    if (!record) {
      console.error(`No se encontró ningún registro con id: ${id}`);
      return null;
    }
  
    return record;
  };

  // Handle form submit
  const handleGenerateDocument = async () => {
    if (!selectedProject || !selectedTemplate) {
      alert('Por favor, seleccione un proyecto y una plantilla.');
      return;
    }

    setIsLoading(true);
    try {
      const payload = {
        url_plantilla: findRecordById(templates,selectedTemplate).url_documento,
        datos: formData,
        nameFile: nameFile
      };
      const response = await axios.post(`${EXTERNAL_API_PATHS.plantillaDocumentos}/documento`, payload);
      console.log('Response:', response.data);
      getDocument(response.data.output_key);
    } catch (error) {
      console.error('Error al generar el documento:', error);
      alert('Error al generar el documento.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSaveData = async () => {
   
    setIsLoading2(true);
    try {
      const payload = {
        req_data_tramite: formData,
      };
      const response = await axios.put(`${EXTERNAL_API_PATHS.tramites}/${idTramite}`, payload);
      alert('Actualizado exitosamente!');
    } catch (error) {
      console.error('Error al generar el documento:', error);
      alert('Error al generar el documento.');
    } finally {
      setIsLoading2(false);
    }
  };

  function mapJsonToFormat(sourceJson) {
    const compradores = [];
  
    // Primer comprador
    if (sourceJson["Nombre Primer comprador"]) {
      compradores.push({
        nombre: sourceJson["Nombre Primer comprador"] || "",
        cedula: sourceJson["No De Cedula Primer comprador"] || "",
        ciudad_residente: sourceJson["Domicilio Primer comprador"] || "",
        estado_civil: sourceJson["Estado civil Primer comprador"] || "",
        nacionalidad: "", // Puede incluirse lógica adicional si se necesita
        tipoDocumento: "", // Ajusta según sea necesario
        telefono: sourceJson["Numero Celular Primer comprador"] || "",
        actividad_economica: sourceJson["Ocupación Primer comprador"] || "",
        domicilio: sourceJson["Domicilio Primer comprador"] || "",
        direccion_domicilio: sourceJson["Dirección Primer comprador"] || "",
        conyugue: {
          nombre: sourceJson["En calidad de cónyuge Primer comprador"] || "",
          identificacion: "", // Ajusta según sea necesario
          nacionalidad: "", // Ajusta según sea necesario
          tipoDocumento: "", // Ajusta según sea necesario
          telefono: "", // Ajusta según sea necesario
          actividad_economica: "", // Ajusta según sea necesario
          domicilio: "", // Ajusta según sea necesario
          direccion_domicilio: "" // Ajusta según sea necesario
        },
        apoderado: {
          nombre: sourceJson["Apoderado Primer comprador"] || "",
          domicilio: "", // Ajusta según sea necesario
          tipo_documento: "", // Ajusta según sea necesario
          identificacion: "" // Ajusta según sea necesario
        }
      });
    }
  
    // Segundo comprador
    if (sourceJson["Nombre Segundo comprador"] && sourceJson["Nombre Segundo comprador"] !== "---") {
      compradores.push({
        nombre: sourceJson["Nombre Segundo comprador"] || "",
        cedula: sourceJson["No De Cedula Segundo comprador"] || "",
        ciudad_residente: sourceJson["Domicilio Segundo comprador"] || "",
        estado_civil: sourceJson["Estado civil Segundo comprador"] || "",
        nacionalidad: "", // Puede incluirse lógica adicional si se necesita
        tipoDocumento: "", // Ajusta según sea necesario
        telefono: sourceJson["Numero Celular Segundo comprador"] || "",
        actividad_economica: sourceJson["Ocupación Segundo comprador"] || "",
        domicilio: sourceJson["Domicilio Segundo comprador"] || "",
        direccion_domicilio: sourceJson["Dirección Segundo comprador"] || "",
        conyugue: {
          nombre: sourceJson["En calidad de cónyuge Segundo comprador"] || "",
          identificacion: "", // Ajusta según sea necesario
          nacionalidad: "", // Ajusta según sea necesario
          tipoDocumento: "", // Ajusta según sea necesario
          telefono: "", // Ajusta según sea necesario
          actividad_economica: "", // Ajusta según sea necesario
          domicilio: "", // Ajusta según sea necesario
          direccion_domicilio: "" // Ajusta según sea necesario
        },
        apoderado: {
          nombre: sourceJson["Apoderado Segundo comprador"] || "",
          domicilio: "", // Ajusta según sea necesario
          tipo_documento: "", // Ajusta según sea necesario
          identificacion: "" // Ajusta según sea necesario
        }
      });
    }
  
    // JSON mapeado final
    const mappedJson = {
      escritura_numero: "",
      fecha_escritura: sourceJson["Fecha pactada escritura"] || "",
      valor_venta: sourceJson["Valor de la Venta"]? parseFloat(sourceJson["Valor de la Venta"].replace(/[^0-9.]/g, "").replace(/\.(?=\d{3})/g, "")) : "0",
      valor_avaluo: "", // Puede incluirse lógica adicional si se necesita
      valor_anticipo: sourceJson["Valor Recibido"]?parseFloat(sourceJson["Valor Recibido"].replace(/[^0-9.]/g, "").replace(/\.(?=\d{3})/g, "")) : "0",
      certificado_numero: "", // Ajusta según sea necesario
      fecha_certificado: "", // Ajusta según sea necesario
      derechos_notariales: "", // Ajusta según sea necesario
      numero_resolución: "", // Ajusta según sea necesario
      fecha_resolución: "", // Ajusta según sea necesario
      recaudo_fondo: "", // Ajusta según sea necesario
      recaudo_super: "", // Ajusta según sea necesario
      iva: "", // Ajusta según sea necesario
      pago_retefuente: "", // Ajusta según sea necesario
      numero_matricula_inmobiliaria: sourceJson["Matrícula No."] || "",
      apartamento_numero: sourceJson["Inmueble. No."] || "",
      nombre_notario: "", // Ajusta según sea necesario
      nombre_notario_firma: "", // Ajusta según sea necesario
      notario_encargado: "", // Ajusta según sea necesario
      linderos: "", // Ajusta según sea necesario
      acredito: sourceJson["Entidad Bancaria"] || "",
      valor_cancelacion_hipoteca: "", // Ajusta según sea necesario
      banco_credito: sourceJson["Entidad Bancaria"] || "",
      valor_credito: sourceJson["Valor del Crédito"]?parseFloat(sourceJson["Valor del Crédito"].replace(/[^0-9.]/g, "").replace(/\.(?=\d{3})/g, "")) : "0",
      subsidio: sourceJson["Valor del Subsidio 1"] !== "" ? "si" : "no",
      nombre_caja_compensacion: sourceJson["Entidad Subsidio de Vivienda 1"] || "",
      valor_subsidio_numeros: sourceJson["Valor del Subsidio 1"]?parseFloat(sourceJson["Valor del Subsidio 1"].replace(/[^0-9.]/g, "").replace(/\.(?=\d{3})/g, "")) : "0",
      fecha_acta_subsidio: "", // Ajusta según sea necesario
      numero_acta_subsidio: "", // Ajusta según sea necesario
      compradores: compradores
    };
  
    return mappedJson;
  }

  const getDocument = async (path) => {
    try{
    let fileName = path.split("/").pop();
    const payload = { path: path, fileName:fileName };
    const response = await  axios.post(EXTERNAL_API_PATHS.files,payload,{ responseType: 'blob'})

    if (response.status == 200) {
         // Crear un Blob a partir de la respuesta binaria
        const blob = new Blob([response.data], { type: response.headers['content-type'] });

        // Crear una URL para el Blob
        const url = window.URL.createObjectURL(blob);

        // Crear un enlace temporal para la descarga
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = fileName; // Nombre con el que se descargará el archivo

        // Añadir el enlace al DOM y simular un clic
        document.body.appendChild(a);
        a.click();

        // Limpiar el DOM y revocar la URL
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
    } else {
      console.error('Error en la descarga del archivo:', response.statusText);
    }

    
  } catch (error) {
    console.error('Error en la descarga del archivo:', error);
  }
   }
  
  
  
  return (
    <Box p={4}>
      {/* Selección de Proyecto y Plantilla */}
      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
          Selección de Proyecto y Plantilla
        </Typography>
        <Box mb={2}>
          <label style={{ marginRight: '10px' }}>Proyectos:</label>
          <select
            value={selectedProject || ''}
            onChange={(e) => setSelectedProject(e.target.value)}
            style={{
              padding: '8px',
              borderRadius: '5px',
              border: '1px solid #ccc',
            }}
          >
            <option value="">Seleccione un proyecto...</option>
            {projects.map((project) => (
              <option key={project.codigo} value={project.codigo}>
                {project.proyecto}
              </option>
            ))}
          </select>
        </Box>
        <Box>
          <label style={{ marginRight: '10px' }}>Plantillas:</label>
          <select
            value={selectedTemplate || ''}
            onChange={(e) => setSelectedTemplate(e.target.value)}
            style={{
              padding: '8px',
              borderRadius: '5px',
              border: '1px solid #ccc',
            }}
          >
            <option value="">Seleccione una plantilla...</option>
            {templates.map((template) => (
              <option key={template.id} value={template.id}>
                {template.nombre_plantilla}
              </option>
            ))}
          </select>
        </Box>
      </Box>

      {/* Formulario */}
      <Formulario initialData={formData} setFormData={setFormData} />

      {/* Botón para Generar Documento */}
      <Box mt={4} textAlign="center">
        <Button
          variant="contained"
          color="primary"
          onClick={handleGenerateDocument}
          disabled={isLoading}
        >
          {isLoading ? (
            <>
              <CircularProgress size={20} style={{ marginRight: '10px' }} />
              Generando Documento...
            </>
          ) : (
            'Generar Documento'
          )}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSaveData}
          disabled={isLoading2}
        >
          {isLoading2 ? (
            <>
              <CircularProgress size={20} style={{ marginRight: '10px' }} />
              Guardando Información...
            </>
          ) : (
            'Guardar Datos'
          )}
        </Button>
      </Box>
    </Box>
  );
}

export default App;
