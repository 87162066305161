import React, { useState, useCallback } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import AppRegistrationIcon from '@material-ui/icons/Apps';
import DialogTitle from '@material-ui/core/DialogTitle';

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Button from "components/CustomButtons/Button.js";
import App from "../FormularioActualizacionDatos/App"

const useStyles = makeStyles(theme => ({
    root: { color: 'black', fontSize: '18px' },
}));

export default function AuteDocuPrivado({dataJson, idPlantilla, idTramite, nameFile, reqDataTramite}) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const key = 'updatable';
    const handleClickOpen = useCallback(() => {
        setOpen(true);
    }, []);

    const handleClose = useCallback(() => {
        setOpen(false);
    }, []);


    return (
        <>
            <Button color="success" className={classes.actionButton} onClick={handleClickOpen}>
                <AppRegistrationIcon className={classes.icon} />
              </Button>
            <Dialog fullWidth={true} maxWidth="md" open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title" style={{ paddingTop: '25px' }}>
                 Minuta Jaramillo Mora - {dataJson.Proyecto} - {dataJson.Etapa}
                </DialogTitle>
                <DialogContent>
                    <App dataJson={dataJson} idPlantilla={idPlantilla} idTramite={idTramite} nameFile={nameFile} reqDataTramite={reqDataTramite}></App>
                </DialogContent>
            </Dialog>
        </>
    );
}
