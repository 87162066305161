export const  departamentos = [
    {"id":1,"name":"Amazonas","code":"91"},
    {"id":2,"name":"Antioquia","code":"05"},
    {"id":3,"name":"Arauca","code":"81"},
    {"id":4,"name":"Atlántico","code":"08"},
    {"id":5,"name":"Bogotá","code":"11"},
    {"id":6,"name":"Bolívar","code":"13"},
    {"id":7,"name":"Boyacá","code":"15"},
    {"id":8,"name":"Caldas","code":"17"},
    {"id":9,"name":"Caquetá","code":"18"},
    {"id":10,"name":"Casanare","code":"85"},
    {"id":11,"name":"Cauca","code":"19"},
    {"id":12,"name":"Cesar","code":"20"},
    {"id":13,"name":"Chocó","code":"27"},
    {"id":14,"name":"Córdoba","code":"23"},
    {"id":15,"name":"Cundinamarca","code":"25"},
    {"id":16,"name":"Guainía","code":"94"},
    {"id":17,"name":"Guaviare","code":"95"},
    {"id":18,"name":"Huila","code":"41"},
    {"id":19,"name":"La Guajira","code":"44"},
    {"id":20,"name":"Magdalena","code":"47"},
    {"id":21,"name":"Meta","code":"50"},
    {"id":22,"name":"Nariño","code":"52"},
    {"id":23,"name":"Norte de Santander","code":"54"},
    {"id":24,"name":"Putumayo","code":"86"},
    {"id":25,"name":"Quindío","code":"63"},
    {"id":26,"name":"Risaralda","code":"66"},
    {"id":27,"name":"San Andrés y Providencia","code":"88"},
    {"id":28,"name":"Santander","code":"68"},
    {"id":29,"name":"Sucre","code":"70"},
    {"id":30,"name":"Tolima","code":"73"},
    {"id":31,"name":"Valle del Cauca","code":"76"},
    {"id":32,"name":"Vaupés","code":"97"},
    {"id":33,"name":"Vichada","code":"99"}
];


export const municipios = 
[
    {"id":1,"id_depto":2, "name":"Medellín",	"code":"05001"},
    {"id":2,"id_depto":2, "name":"Abejorral",	"code":"05002"},
    {"id":3,"id_depto":2, "name":"Abriaquí",	"code":"05004"},
    {"id":4,"id_depto":2, "name":"Alejandría",	"code":"05021"},
    {"id":5,"id_depto":2, "name":"Amagá",	"code":"05030"},
    {"id":6,"id_depto":2, "name":"Amalfi",	"code":"05031"},
    {"id":7,"id_depto":2, "name":"Andes",	"code":"05034"},
    {"id":8,"id_depto":2, "name":"Angelópolis",	"code":"05036"},
    {"id":9,"id_depto":2, "name":"Angostura",	"code":"05038"},
    {"id":10,"id_depto":2, "name":"Anorí",	"code":"05040"},
    {"id":11,"id_depto":2, "name":"Santa Fé De Antioquia",	"code":"05042"},
    {"id":12,"id_depto":2, "name":"Anzá",	"code":"05044"},
    {"id":13,"id_depto":2, "name":"Apartadó",	"code":"05045"},
    {"id":14,"id_depto":2, "name":"Arboletes",	"code":"05051"},
    {"id":15,"id_depto":2, "name":"Argelia",	"code":"05055"},
    {"id":16,"id_depto":2, "name":"Armenia",	"code":"05059"},
    {"id":17,"id_depto":2, "name":"Barbosa",	"code":"05079"},
    {"id":18,"id_depto":2, "name":"Belmira",	"code":"05086"},
    {"id":19,"id_depto":2, "name":"Bello",	"code":"05088"},
    {"id":20,"id_depto":2, "name":"Betania",	"code":"05091"},
    {"id":21,"id_depto":2, "name":"Betulia",	"code":"05093"},
    {"id":22,"id_depto":2, "name":"Ciudad Bolívar", 	"code":"05101"},
    {"id":23,"id_depto":2, "name":"Briceño",	"code":"05107"},
    {"id":24,"id_depto":2, "name":"Buriticá",	"code":"05113"},
    {"id":25,"id_depto":2, "name":"Cáceres",	"code":"05120"},
    {"id":26,"id_depto":2, "name":"Caicedo",	"code":"05125"},
    {"id":27,"id_depto":2, "name":"Caldas",	"code":"05129"},
    {"id":28,"id_depto":2, "name":"Campamento",	"code":"05134"},
    {"id":29,"id_depto":2, "name":"Cañasgordas",	"code":"05138"},
    {"id":30,"id_depto":2, "name":"Caracolí",	"code":"05142"},
    {"id":31,"id_depto":2, "name":"Caramanta",	"code":"05145"},
    {"id":32,"id_depto":2, "name":"Carepa",	"code":"05147"},
    {"id":33,"id_depto":2, "name":"El Carmen De Viboral",	"code":"05148"},
    {"id":34,"id_depto":2, "name":"Carolina",	"code":"05150"},
    {"id":35,"id_depto":2, "name":"Caucasia",	"code":"05154"},
    {"id":36,"id_depto":2, "name":"Chigorodó",	"code":"05172"},
    {"id":37,"id_depto":2, "name":"Cisneros",	"code":"05190"},
    {"id":38,"id_depto":2, "name":"Cocorná",	"code":"05197"},
    {"id":39,"id_depto":2, "name":"Concepción",	"code":"05206"},
    {"id":40,"id_depto":2, "name":"Concordia",	"code":"05209"},
    {"id":41,"id_depto":2, "name":"Copacabana",	"code":"05212"},
    {"id":42,"id_depto":2, "name":"Dabeiba",	"code":"05234"},
    {"id":43,"id_depto":2, "name":"Donmatías",	"code":"05237"},
    {"id":44,"id_depto":2, "name":"Ebéjico",	"code":"05240"},
    {"id":45,"id_depto":2, "name":"El Bagre", 	"code":"05250"},
    {"id":46,"id_depto":2, "name":"Entrerríos",	"code":"05264"},
    {"id":47,"id_depto":2, "name":"Envigado",	"code":"05266"},
    {"id":48,"id_depto":2, "name":"Fredonia",	"code":"05282"},
    {"id":49,"id_depto":2, "name":"Frontino",	"code":"05284"},
    {"id":50,"id_depto":2, "name":"Giraldo",	"code":"05306"},
    {"id":51,"id_depto":2, "name":"Girardota",	"code":"05308"},
    {"id":52,"id_depto":2, "name":"Gómez Plata", 	"code":"05310"},
    {"id":53,"id_depto":2, "name":"Granada",	"code":"05313"},
    {"id":54,"id_depto":2, "name":"Guadalupe",	"code":"05315"},
    {"id":55,"id_depto":2, "name":"Guarne",	"code":"05318"},
    {"id":56,"id_depto":2, "name":"Guatapé",	"code":"05321"},
    {"id":57,"id_depto":2, "name":"Heliconia",	"code":"05347"},
    {"id":58,"id_depto":2, "name":"Hispania",	"code":"05353"},
    {"id":59,"id_depto":2, "name":"Itagüí",	"code":"05360"},
    {"id":60,"id_depto":2, "name":"Ituango",	"code":"05361"},
    {"id":61,"id_depto":2, "name":"Jardín",	"code":"05364"},
    {"id":62,"id_depto":2, "name":"Jericó",	"code":"05368"},
    {"id":63,"id_depto":2, "name":"La Ceja", 	"code":"05376"},
    {"id":64,"id_depto":2, "name":"La Estrella", 	"code":"05380"},
    {"id":65,"id_depto":2, "name":"La Pintada", 	"code":"05390"},
    {"id":66,"id_depto":2, "name":"La Unión", 	"code":"05400"},
    {"id":67,"id_depto":2, "name":"Liborina",	"code":"05411"},
    {"id":68,"id_depto":2, "name":"Maceo",	"code":"05425"},
    {"id":69,"id_depto":2, "name":"Marinilla",	"code":"05440"},
    {"id":70,"id_depto":2, "name":"Montebello",	"code":"05467"},
    {"id":71,"id_depto":2, "name":"Murindó",	"code":"05475"},
    {"id":72,"id_depto":2, "name":"Mutatá",	"code":"05480"},
    {"id":73,"id_depto":2, "name":"Nariño",	"code":"05483"},
    {"id":74,"id_depto":2, "name":"Necoclí",	"code":"05490"},
    {"id":75,"id_depto":2, "name":"Nechí",	"code":"05495"},
    {"id":76,"id_depto":2, "name":"Olaya",	"code":"05501"},
    {"id":77,"id_depto":2, "name":"Peñol",	"code":"05541"},
    {"id":78,"id_depto":2, "name":"Peque",	"code":"05543"},
    {"id":79,"id_depto":2, "name":"Pueblorrico",	"code":"05576"},
    {"id":80,"id_depto":2, "name":"Puerto Berrío", 	"code":"05579"},
    {"id":81,"id_depto":2, "name":"Puerto Nare", 	"code":"05585"},
    {"id":82,"id_depto":2, "name":"Puerto Triunfo", 	"code":"05591"},
    {"id":83,"id_depto":2, "name":"Remedios",	"code":"05604"},
    {"id":84,"id_depto":2, "name":"Retiro",	"code":"05607"},
    {"id":85,"id_depto":2, "name":"Rionegro",	"code":"05615"},
    {"id":86,"id_depto":2, "name":"Sabanalarga",	"code":"05628"},
    {"id":87,"id_depto":2, "name":"Sabaneta",	"code":"05631"},
    {"id":88,"id_depto":2, "name":"Salgar",	"code":"05642"},
    {"id":89,"id_depto":2, "name":"San Andrés De Cuerquía",	"code":"05647"},
    {"id":90,"id_depto":2, "name":"San Carlos", 	"code":"05649"},
    {"id":91,"id_depto":2, "name":"San Francisco", 	"code":"05652"},
    {"id":92,"id_depto":2, "name":"San Jerónimo", 	"code":"05656"},
    {"id":93,"id_depto":2, "name":"San José De La Montaña", 	"code":"05658"},
    {"id":94,"id_depto":2, "name":"San Juan De Urabá",	"code":"05659"},
    {"id":95,"id_depto":2, "name":"San Luis", 	"code":"05660"},
    {"id":96,"id_depto":2, "name":"San Pedro De Los Milagros", 	"code":"05664"},
    {"id":97,"id_depto":2, "name":"San Pedro De Urabá",	"code":"05665"},
    {"id":98,"id_depto":2, "name":"San Rafael", 	"code":"05667"},
    {"id":99,"id_depto":2, "name":"San Roque", 	"code":"05670"},
    {"id":100,"id_depto":2, "name":"San Vicente Ferrer",	"code":"05674"},
    {"id":101,"id_depto":2, "name":"Santa Bárbara", 	"code":"05679"},
    {"id":102,"id_depto":2, "name":"Santa Rosa De Osos",	"code":"05686"},
    {"id":103,"id_depto":2, "name":"Santo Domingo", 	"code":"05690"},
    {"id":104,"id_depto":2, "name":"El Santuario", 	"code":"05697"},
    {"id":105,"id_depto":2, "name":"Segovia",	"code":"05736"},
    {"id":106,"id_depto":2, "name":"Sonsón",	"code":"05756"},
    {"id":107,"id_depto":2, "name":"Sopetrán",	"code":"05761"},
    {"id":108,"id_depto":2, "name":"Támesis",	"code":"05789"},
    {"id":109,"id_depto":2, "name":"Tarazá",	"code":"05790"},
    {"id":110,"id_depto":2, "name":"Tarso",	"code":"05792"},
    {"id":111,"id_depto":2, "name":"Titiribí",	"code":"05809"},
    {"id":112,"id_depto":2, "name":"Toledo",	"code":"05819"},
    {"id":113,"id_depto":2, "name":"Turbo",	"code":"05837"},
    {"id":114,"id_depto":2, "name":"Uramita",	"code":"05842"},
    {"id":115,"id_depto":2, "name":"Urrao",	"code":"05847"},
    {"id":116,"id_depto":2, "name":"Valdivia",	"code":"05854"},
    {"id":117,"id_depto":2, "name":"Valparaíso",	"code":"05856"},
    {"id":118,"id_depto":2, "name":"Vegachí",	"code":"05858"},
    {"id":119,"id_depto":2, "name":"Venecia",	"code":"05861"},
    {"id":120,"id_depto":2, "name":"Vigía Del Fuerte",	"code":"05873"},
    {"id":121,"id_depto":2, "name":"Yalí",	"code":"05885"},
    {"id":122,"id_depto":2, "name":"Yarumal",	"code":"05887"},
    {"id":123,"id_depto":2, "name":"Yolombó",	"code":"05890"},
    {"id":124,"id_depto":2, "name":"Yondó",	"code":"05893"},
    {"id":125,"id_depto":2, "name":"Zaragoza",	"code":"05895"},
    {"id":126,"id_depto":4, "name":"Barranquilla",	"code":"08001"},
    {"id":127,"id_depto":4, "name":"Baranoa",	"code":"08078"},
    {"id":128,"id_depto":4, "name":"Campo De La Cruz",	"code":"08137"},
    {"id":129,"id_depto":4, "name":"Candelaria",	"code":"08141"},
    {"id":130,"id_depto":4, "name":"Galapa",	"code":"08296"},
    {"id":131,"id_depto":4, "name":"Juan De Acosta",	"code":"08372"},
    {"id":132,"id_depto":4, "name":"Luruaco",	"code":"08421"},
    {"id":133,"id_depto":4, "name":"Malambo",	"code":"08433"},
    {"id":134,"id_depto":4, "name":"Manatí",	"code":"08436"},
    {"id":135,"id_depto":4, "name":"Palmar De Varela",	"code":"08520"},
    {"id":136,"id_depto":4, "name":"Piojó",	"code":"08549"},
    {"id":137,"id_depto":4, "name":"Polonuevo",	"code":"08558"},
    {"id":138,"id_depto":4, "name":"Ponedera",	"code":"08560"},
    {"id":139,"id_depto":4, "name":"Puerto Colombia", 	"code":"08573"},
    {"id":140,"id_depto":4, "name":"Repelón",	"code":"08606"},
    {"id":141,"id_depto":4, "name":"Sabanagrande",	"code":"08634"},
    {"id":142,"id_depto":4, "name":"Sabanalarga",	"code":"08638"},
    {"id":143,"id_depto":4, "name":"Santa Lucía", 	"code":"08675"},
    {"id":144,"id_depto":4, "name":"Santo Tomás", 	"code":"08685"},
    {"id":145,"id_depto":4, "name":"Soledad",	"code":"08758"},
    {"id":146,"id_depto":4, "name":"Suan",	"code":"08770"},
    {"id":147,"id_depto":4, "name":"Tubará",	"code":"08832"},
    {"id":148,"id_depto":4, "name":"Usiacurí",	"code":"08849"},
    {"id":149,"id_depto":5, "name":"Bogotá, D.c.", 	"code":"11001"},
    {"id":150,"id_depto":6, "name":"Cartagena De Indias",	"code":"13001"},
    {"id":151,"id_depto":6, "name":"Achí",	"code":"13006"},
    {"id":152,"id_depto":6, "name":"Altos Del Rosario",	"code":"13030"},
    {"id":153,"id_depto":6, "name":"Arenal",	"code":"13042"},
    {"id":154,"id_depto":6, "name":"Arjona",	"code":"13052"},
    {"id":155,"id_depto":6, "name":"Arroyohondo",	"code":"13062"},
    {"id":156,"id_depto":6, "name":"Barranco De Loba",	"code":"13074"},
    {"id":157,"id_depto":6, "name":"Calamar",	"code":"13140"},
    {"id":158,"id_depto":6, "name":"Cantagallo",	"code":"13160"},
    {"id":159,"id_depto":6, "name":"Cicuco",	"code":"13188"},
    {"id":160,"id_depto":6, "name":"Córdoba",	"code":"13212"},
    {"id":161,"id_depto":6, "name":"Clemencia",	"code":"13222"},
    {"id":162,"id_depto":6, "name":"El Carmen De Bolívar",	"code":"13244"},
    {"id":163,"id_depto":6, "name":"El Guamo", 	"code":"13248"},
    {"id":164,"id_depto":6, "name":"El Peñón", 	"code":"13268"},
    {"id":165,"id_depto":6, "name":"Hatillo De Loba",	"code":"13300"},
    {"id":166,"id_depto":6, "name":"Magangué",	"code":"13430"},
    {"id":167,"id_depto":6, "name":"Mahates",	"code":"13433"},
    {"id":168,"id_depto":6, "name":"Margarita",	"code":"13440"},
    {"id":169,"id_depto":6, "name":"María La Baja",	"code":"13442"},
    {"id":170,"id_depto":6, "name":"Montecristo",	"code":"13458"},
    {"id":171,"id_depto":6, "name":"Mompós",	"code":"13468"},
    {"id":172,"id_depto":6, "name":"Morales",	"code":"13473"},
    {"id":173,"id_depto":6, "name":"Norosí",	"code":"13490"},
    {"id":174,"id_depto":6, "name":"Pinillos",	"code":"13549"},
    {"id":175,"id_depto":6, "name":"Regidor",	"code":"13580"},
    {"id":176,"id_depto":6, "name":"Río Viejo", 	"code":"13600"},
    {"id":177,"id_depto":6, "name":"San Cristóbal", 	"code":"13620"},
    {"id":178,"id_depto":6, "name":"San Estanislao", 	"code":"13647"},
    {"id":179,"id_depto":6, "name":"San Fernando", 	"code":"13650"},
    {"id":180,"id_depto":6, "name":"San Jacinto", 	"code":"13654"},
    {"id":181,"id_depto":6, "name":"San Jacinto Del Cauca",	"code":"13655"},
    {"id":182,"id_depto":6, "name":"San Juan Nepomuceno",	"code":"13657"},
    {"id":183,"id_depto":6, "name":"San Martín De Loba",	"code":"13667"},
    {"id":184,"id_depto":6, "name":"San Pablo", 	"code":"13670"},
    {"id":185,"id_depto":6, "name":"Santa Catalina", 	"code":"13673"},
    {"id":186,"id_depto":6, "name":"Santa Rosa", 	"code":"13683"},
    {"id":187,"id_depto":6, "name":"Santa Rosa Del Sur",	"code":"13688"},
    {"id":188,"id_depto":6, "name":"Simití",	"code":"13744"},
    {"id":189,"id_depto":6, "name":"Soplaviento",	"code":"13760"},
    {"id":190,"id_depto":6, "name":"Talaigua Nuevo", 	"code":"13780"},
    {"id":191,"id_depto":6, "name":"Tiquisio",	"code":"13810"},
    {"id":192,"id_depto":6, "name":"Turbaco",	"code":"13836"},
    {"id":193,"id_depto":6, "name":"Turbaná",	"code":"13838"},
    {"id":194,"id_depto":6, "name":"Villanueva",	"code":"13873"},
    {"id":195,"id_depto":6, "name":"Zambrano",	"code":"13894"},
    {"id":196,"id_depto":7, "name":"Tunja",	"code":"15001"},
    {"id":197,"id_depto":7, "name":"Almeida",	"code":"15022"},
    {"id":198,"id_depto":7, "name":"Aquitania",	"code":"15047"},
    {"id":199,"id_depto":7, "name":"Arcabuco",	"code":"15051"},
    {"id":200,"id_depto":7, "name":"Belén",	"code":"15087"},
    {"id":201,"id_depto":7, "name":"Berbeo",	"code":"15090"},
    {"id":202,"id_depto":7, "name":"Betéitiva",	"code":"15092"},
    {"id":203,"id_depto":7, "name":"Boavita",	"code":"15097"},
    {"id":204,"id_depto":7, "name":"Boyacá",	"code":"15104"},
    {"id":205,"id_depto":7, "name":"Briceño",	"code":"15106"},
    {"id":206,"id_depto":7, "name":"Buenavista",	"code":"15109"},
    {"id":207,"id_depto":7, "name":"Busbanzá",	"code":"15114"},
    {"id":208,"id_depto":7, "name":"Caldas",	"code":"15131"},
    {"id":209,"id_depto":7, "name":"Campohermoso",	"code":"15135"},
    {"id":210,"id_depto":7, "name":"Cerinza",	"code":"15162"},
    {"id":211,"id_depto":7, "name":"Chinavita",	"code":"15172"},
    {"id":212,"id_depto":7, "name":"Chiquinquirá",	"code":"15176"},
    {"id":213,"id_depto":7, "name":"Chiscas",	"code":"15180"},
    {"id":214,"id_depto":7, "name":"Chita",	"code":"15183"},
    {"id":215,"id_depto":7, "name":"Chitaraque",	"code":"15185"},
    {"id":216,"id_depto":7, "name":"Chivatá",	"code":"15187"},
    {"id":217,"id_depto":7, "name":"Ciénega",	"code":"15189"},
    {"id":218,"id_depto":7, "name":"Cómbita",	"code":"15204"},
    {"id":219,"id_depto":7, "name":"Coper",	"code":"15212"},
    {"id":220,"id_depto":7, "name":"Corrales",	"code":"15215"},
    {"id":221,"id_depto":7, "name":"Covarachía",	"code":"15218"},
    {"id":222,"id_depto":7, "name":"Cubará",	"code":"15223"},
    {"id":223,"id_depto":7, "name":"Cucaita",	"code":"15224"},
    {"id":224,"id_depto":7, "name":"Cuítiva",	"code":"15226"},
    {"id":225,"id_depto":7, "name":"Chíquiza",	"code":"15232"},
    {"id":226,"id_depto":7, "name":"Chivor",	"code":"15236"},
    {"id":227,"id_depto":7, "name":"Duitama",	"code":"15238"},
    {"id":228,"id_depto":7, "name":"El Cocuy", 	"code":"15244"},
    {"id":229,"id_depto":7, "name":"El Espino", 	"code":"15248"},
    {"id":230,"id_depto":7, "name":"Firavitoba",	"code":"15272"},
    {"id":231,"id_depto":7, "name":"Floresta",	"code":"15276"},
    {"id":232,"id_depto":7, "name":"Gachantivá",	"code":"15293"},
    {"id":233,"id_depto":7, "name":"Gámeza",	"code":"15296"},
    {"id":234,"id_depto":7, "name":"Garagoa",	"code":"15299"},
    {"id":235,"id_depto":7, "name":"Guacamayas",	"code":"15317"},
    {"id":236,"id_depto":7, "name":"Guateque",	"code":"15322"},
    {"id":237,"id_depto":7, "name":"Guayatá",	"code":"15325"},
    {"id":238,"id_depto":7, "name":"Güicán De La Sierra",	"code":"15332"},
    {"id":239,"id_depto":7, "name":"Iza",	"code":"15362"},
    {"id":240,"id_depto":7, "name":"Jenesano",	"code":"15367"},
    {"id":241,"id_depto":7, "name":"Jericó",	"code":"15368"},
    {"id":242,"id_depto":7, "name":"Labranzagrande",	"code":"15377"},
    {"id":243,"id_depto":7, "name":"La Capilla", 	"code":"15380"},
    {"id":244,"id_depto":7, "name":"La Victoria", 	"code":"15401"},
    {"id":245,"id_depto":7, "name":"La Uvita", 	"code":"15403"},
    {"id":246,"id_depto":7, "name":"Villa De Leyva",	"code":"15407"},
    {"id":247,"id_depto":7, "name":"Macanal",	"code":"15425"},
    {"id":248,"id_depto":7, "name":"Maripí",	"code":"15442"},
    {"id":249,"id_depto":7, "name":"Miraflores",	"code":"15455"},
    {"id":250,"id_depto":7, "name":"Mongua",	"code":"15464"},
    {"id":251,"id_depto":7, "name":"Monguí",	"code":"15466"},
    {"id":252,"id_depto":7, "name":"Moniquirá",	"code":"15469"},
    {"id":253,"id_depto":7, "name":"Motavita",	"code":"15476"},
    {"id":254,"id_depto":7, "name":"Muzo",	"code":"15480"},
    {"id":255,"id_depto":7, "name":"Nobsa",	"code":"15491"},
    {"id":256,"id_depto":7, "name":"Nuevo Colón", 	"code":"15494"},
    {"id":257,"id_depto":7, "name":"Oicatá",	"code":"15500"},
    {"id":258,"id_depto":7, "name":"Otanche",	"code":"15507"},
    {"id":259,"id_depto":7, "name":"Pachavita",	"code":"15511"},
    {"id":260,"id_depto":7, "name":"Páez",	"code":"15514"},
    {"id":261,"id_depto":7, "name":"Paipa",	"code":"15516"},
    {"id":262,"id_depto":7, "name":"Pajarito",	"code":"15518"},
    {"id":263,"id_depto":7, "name":"Panqueba",	"code":"15522"},
    {"id":264,"id_depto":7, "name":"Pauna",	"code":"15531"},
    {"id":265,"id_depto":7, "name":"Paya",	"code":"15533"},
    {"id":266,"id_depto":7, "name":"Paz De Río",	"code":"15537"},
    {"id":267,"id_depto":7, "name":"Pesca",	"code":"15542"},
    {"id":268,"id_depto":7, "name":"Pisba",	"code":"15550"},
    {"id":269,"id_depto":7, "name":"Puerto Boyacá", 	"code":"15572"},
    {"id":270,"id_depto":7, "name":"Quípama",	"code":"15580"},
    {"id":271,"id_depto":7, "name":"Ramiriquí",	"code":"15599"},
    {"id":272,"id_depto":7, "name":"Ráquira",	"code":"15600"},
    {"id":273,"id_depto":7, "name":"Rondón",	"code":"15621"},
    {"id":274,"id_depto":7, "name":"Saboyá",	"code":"15632"},
    {"id":275,"id_depto":7, "name":"Sáchica",	"code":"15638"},
    {"id":276,"id_depto":7, "name":"Samacá",	"code":"15646"},
    {"id":277,"id_depto":7, "name":"San Eduardo", 	"code":"15660"},
    {"id":278,"id_depto":7, "name":"San José De Pare",	"code":"15664"},
    {"id":279,"id_depto":7, "name":"San Luis De Gaceno",	"code":"15667"},
    {"id":280,"id_depto":7, "name":"San Mateo", 	"code":"15673"},
    {"id":281,"id_depto":7, "name":"San Miguel De Sema",	"code":"15676"},
    {"id":282,"id_depto":7, "name":"San Pablo De Borbur",	"code":"15681"},
    {"id":283,"id_depto":7, "name":"Santana",	"code":"15686"},
    {"id":284,"id_depto":7, "name":"Santa María", 	"code":"15690"},
    {"id":285,"id_depto":7, "name":"Santa Rosa De Viterbo",	"code":"15693"},
    {"id":286,"id_depto":7, "name":"Santa Sofía", 	"code":"15696"},
    {"id":287,"id_depto":7, "name":"Sativanorte",	"code":"15720"},
    {"id":288,"id_depto":7, "name":"Sativasur",	"code":"15723"},
    {"id":289,"id_depto":7, "name":"Siachoque",	"code":"15740"},
    {"id":290,"id_depto":7, "name":"Soatá",	"code":"15753"},
    {"id":291,"id_depto":7, "name":"Socotá",	"code":"15755"},
    {"id":292,"id_depto":7, "name":"Socha",	"code":"15757"},
    {"id":293,"id_depto":7, "name":"Sogamoso",	"code":"15759"},
    {"id":294,"id_depto":7, "name":"Somondoco",	"code":"15761"},
    {"id":295,"id_depto":7, "name":"Sora",	"code":"15762"},
    {"id":296,"id_depto":7, "name":"Sotaquirá",	"code":"15763"},
    {"id":297,"id_depto":7, "name":"Soracá",	"code":"15764"},
    {"id":298,"id_depto":7, "name":"Susacón",	"code":"15774"},
    {"id":299,"id_depto":7, "name":"Sutamarchán",	"code":"15776"},
    {"id":300,"id_depto":7, "name":"Sutatenza",	"code":"15778"},
    {"id":301,"id_depto":7, "name":"Tasco",	"code":"15790"},
    {"id":302,"id_depto":7, "name":"Tenza",	"code":"15798"},
    {"id":303,"id_depto":7, "name":"Tibaná",	"code":"15804"},
    {"id":304,"id_depto":7, "name":"Tibasosa",	"code":"15806"},
    {"id":305,"id_depto":7, "name":"Tinjacá",	"code":"15808"},
    {"id":306,"id_depto":7, "name":"Tipacoque",	"code":"15810"},
    {"id":307,"id_depto":7, "name":"Toca",	"code":"15814"},
    {"id":308,"id_depto":7, "name":"Togüí",	"code":"15816"},
    {"id":309,"id_depto":7, "name":"Tópaga",	"code":"15820"},
    {"id":310,"id_depto":7, "name":"Tota",	"code":"15822"},
    {"id":311,"id_depto":7, "name":"Tununguá",	"code":"15832"},
    {"id":312,"id_depto":7, "name":"Turmequé",	"code":"15835"},
    {"id":313,"id_depto":7, "name":"Tuta",	"code":"15837"},
    {"id":314,"id_depto":7, "name":"Tutazá",	"code":"15839"},
    {"id":315,"id_depto":7, "name":"Úmbita",	"code":"15842"},
    {"id":316,"id_depto":7, "name":"Ventaquemada",	"code":"15861"},
    {"id":317,"id_depto":7, "name":"Viracachá",	"code":"15879"},
    {"id":318,"id_depto":7, "name":"Zetaquira",	"code":"15897"},
    {"id":319,"id_depto":8, "name":"Manizales",	"code":"17001"},
    {"id":320,"id_depto":8, "name":"Aguadas",	"code":"17013"},
    {"id":321,"id_depto":8, "name":"Anserma",	"code":"17042"},
    {"id":322,"id_depto":8, "name":"Aranzazu",	"code":"17050"},
    {"id":323,"id_depto":8, "name":"Belalcázar",	"code":"17088"},
    {"id":324,"id_depto":8, "name":"Chinchiná",	"code":"17174"},
    {"id":325,"id_depto":8, "name":"Filadelfia",	"code":"17272"},
    {"id":326,"id_depto":8, "name":"La Dorada", 	"code":"17380"},
    {"id":327,"id_depto":8, "name":"La Merced", 	"code":"17388"},
    {"id":328,"id_depto":8, "name":"Manzanares",	"code":"17433"},
    {"id":329,"id_depto":8, "name":"Marmato",	"code":"17442"},
    {"id":330,"id_depto":8, "name":"Marquetalia",	"code":"17444"},
    {"id":331,"id_depto":8, "name":"Marulanda",	"code":"17446"},
    {"id":332,"id_depto":8, "name":"Neira",	"code":"17486"},
    {"id":333,"id_depto":8, "name":"Norcasia",	"code":"17495"},
    {"id":334,"id_depto":8, "name":"Pácora",	"code":"17513"},
    {"id":335,"id_depto":8, "name":"Palestina",	"code":"17524"},
    {"id":336,"id_depto":8, "name":"Pensilvania",	"code":"17541"},
    {"id":337,"id_depto":8, "name":"Riosucio",	"code":"17614"},
    {"id":338,"id_depto":8, "name":"Risaralda",	"code":"17616"},
    {"id":339,"id_depto":8, "name":"Salamina",	"code":"17653"},
    {"id":340,"id_depto":8, "name":"Samaná",	"code":"17662"},
    {"id":341,"id_depto":8, "name":"San José", 	"code":"17665"},
    {"id":342,"id_depto":8, "name":"Supía",	"code":"17777"},
    {"id":343,"id_depto":8, "name":"Victoria",	"code":"17867"},
    {"id":344,"id_depto":8, "name":"Villamaría",	"code":"17873"},
    {"id":345,"id_depto":8, "name":"Viterbo",	"code":"17877"},
    {"id":346,"id_depto":9, "name":"Florencia",	"code":"18001"},
    {"id":347,"id_depto":9, "name":"Albania",	"code":"18029"},
    {"id":348,"id_depto":9, "name":"Belén De Los Andaquíes",	"code":"18094"},
    {"id":349,"id_depto":9, "name":"Cartagena Del Chairá",	"code":"18150"},
    {"id":350,"id_depto":9, "name":"Curillo",	"code":"18205"},
    {"id":351,"id_depto":9, "name":"El Doncello", 	"code":"18247"},
    {"id":352,"id_depto":9, "name":"El Paujíl", 	"code":"18256"},
    {"id":353,"id_depto":9, "name":"La Montañita", 	"code":"18410"},
    {"id":354,"id_depto":9, "name":"Milán",	"code":"18460"},
    {"id":355,"id_depto":9, "name":"Morelia",	"code":"18479"},
    {"id":356,"id_depto":9, "name":"Puerto Rico", 	"code":"18592"},
    {"id":357,"id_depto":9, "name":"San José Del Fragua",	"code":"18610"},
    {"id":358,"id_depto":9, "name":"San Vicente Del Caguán",	"code":"18753"},
    {"id":359,"id_depto":9, "name":"Solano",	"code":"18756"},
    {"id":360,"id_depto":9, "name":"Solita",	"code":"18785"},
    {"id":361,"id_depto":9, "name":"Valparaíso",	"code":"18860"},
    {"id":362,"id_depto":11, "name":"Popayán",	"code":"19001"},
    {"id":363,"id_depto":11, "name":"Almaguer",	"code":"19022"},
    {"id":364,"id_depto":11, "name":"Argelia",	"code":"19050"},
    {"id":365,"id_depto":11, "name":"Balboa",	"code":"19075"},
    {"id":366,"id_depto":11, "name":"Bolívar",	"code":"19100"},
    {"id":367,"id_depto":11, "name":"Buenos Aires", 	"code":"19110"},
    {"id":368,"id_depto":11, "name":"Cajibío",	"code":"19130"},
    {"id":369,"id_depto":11, "name":"Caldono",	"code":"19137"},
    {"id":370,"id_depto":11, "name":"Caloto",	"code":"19142"},
    {"id":371,"id_depto":11, "name":"Corinto",	"code":"19212"},
    {"id":372,"id_depto":11, "name":"El Tambo", 	"code":"19256"},
    {"id":373,"id_depto":11, "name":"Florencia",	"code":"19290"},
    {"id":374,"id_depto":11, "name":"Guachené",	"code":"19300"},
    {"id":375,"id_depto":11, "name":"Guapí",	"code":"19318"},
    {"id":376,"id_depto":11, "name":"Inzá",	"code":"19355"},
    {"id":377,"id_depto":11, "name":"Jambaló",	"code":"19364"},
    {"id":378,"id_depto":11, "name":"La Sierra", 	"code":"19392"},
    {"id":379,"id_depto":11, "name":"La Vega", 	"code":"19397"},
    {"id":380,"id_depto":11, "name":"López De Micay",	"code":"19418"},
    {"id":381,"id_depto":11, "name":"Mercaderes",	"code":"19450"},
    {"id":382,"id_depto":11, "name":"Miranda",	"code":"19455"},
    {"id":383,"id_depto":11, "name":"Morales",	"code":"19473"},
    {"id":384,"id_depto":11, "name":"Padilla",	"code":"19513"},
    {"id":385,"id_depto":11, "name":"Páez",	"code":"19517"},
    {"id":386,"id_depto":11, "name":"Patía",	"code":"19532"},
    {"id":387,"id_depto":11, "name":"Piamonte",	"code":"19533"},
    {"id":388,"id_depto":11, "name":"Piendamó - Tunía",	"code":"19548"},
    {"id":389,"id_depto":11, "name":"Puerto Tejada", 	"code":"19573"},
    {"id":390,"id_depto":11, "name":"Puracé",	"code":"19585"},
    {"id":391,"id_depto":11, "name":"Rosas",	"code":"19622"},
    {"id":392,"id_depto":11, "name":"San Sebastián", 	"code":"19693"},
    {"id":393,"id_depto":11, "name":"Santander De Quilichao",	"code":"19698"},
    {"id":394,"id_depto":11, "name":"Santa Rosa", 	"code":"19701"},
    {"id":395,"id_depto":11, "name":"Silvia",	"code":"19743"},
    {"id":396,"id_depto":11, "name":"Sotara",	"code":"19760"},
    {"id":397,"id_depto":11, "name":"Suárez",	"code":"19780"},
    {"id":398,"id_depto":11, "name":"Sucre",	"code":"19785"},
    {"id":399,"id_depto":11, "name":"Timbío",	"code":"19807"},
    {"id":400,"id_depto":11, "name":"Timbiquí",	"code":"19809"},
    {"id":401,"id_depto":11, "name":"Toribío",	"code":"19821"},
    {"id":402,"id_depto":11, "name":"Totoró",	"code":"19824"},
    {"id":403,"id_depto":11, "name":"Villa Rica", 	"code":"19845"},
    {"id":404,"id_depto":12, "name":"Valledupar",	"code":"20001"},
    {"id":405,"id_depto":12, "name":"Aguachica",	"code":"20011"},
    {"id":406,"id_depto":12, "name":"Agustín Codazzi", 	"code":"20013"},
    {"id":407,"id_depto":12, "name":"Astrea",	"code":"20032"},
    {"id":408,"id_depto":12, "name":"Becerril",	"code":"20045"},
    {"id":409,"id_depto":12, "name":"Bosconia",	"code":"20060"},
    {"id":410,"id_depto":12, "name":"Chimichagua",	"code":"20175"},
    {"id":411,"id_depto":12, "name":"Chiriguaná",	"code":"20178"},
    {"id":412,"id_depto":12, "name":"Curumaní",	"code":"20228"},
    {"id":413,"id_depto":12, "name":"El Copey", 	"code":"20238"},
    {"id":414,"id_depto":12, "name":"El Paso", 	"code":"20250"},
    {"id":415,"id_depto":12, "name":"Gamarra",	"code":"20295"},
    {"id":416,"id_depto":12, "name":"González",	"code":"20310"},
    {"id":417,"id_depto":12, "name":"La Gloria", 	"code":"20383"},
    {"id":418,"id_depto":12, "name":"La Jagua De Ibirico",	"code":"20400"},
    {"id":419,"id_depto":12, "name":"Manaure Balcón Del Cesar",	"code":"20443"},
    {"id":420,"id_depto":12, "name":"Pailitas",	"code":"20517"},
    {"id":421,"id_depto":12, "name":"Pelaya",	"code":"20550"},
    {"id":422,"id_depto":12, "name":"Pueblo Bello", 	"code":"20570"},
    {"id":423,"id_depto":12, "name":"Río De Oro",	"code":"20614"},
    {"id":424,"id_depto":12, "name":"La Paz", 	"code":"20621"},
    {"id":425,"id_depto":12, "name":"San Alberto", 	"code":"20710"},
    {"id":426,"id_depto":12, "name":"San Diego", 	"code":"20750"},
    {"id":427,"id_depto":12, "name":"San Martín", 	"code":"20770"},
    {"id":428,"id_depto":12, "name":"Tamalameque",	"code":"20787"},
    {"id":429,"id_depto":14, "name":"Montería",	"code":"23001"},
    {"id":430,"id_depto":14, "name":"Ayapel",	"code":"23068"},
    {"id":431,"id_depto":14, "name":"Buenavista",	"code":"23079"},
    {"id":432,"id_depto":14, "name":"Canalete",	"code":"23090"},
    {"id":433,"id_depto":14, "name":"Cereté",	"code":"23162"},
    {"id":434,"id_depto":14, "name":"Chimá",	"code":"23168"},
    {"id":435,"id_depto":14, "name":"Chinú",	"code":"23182"},
    {"id":436,"id_depto":14, "name":"Ciénaga De Oro",	"code":"23189"},
    {"id":437,"id_depto":14, "name":"Cotorra",	"code":"23300"},
    {"id":438,"id_depto":14, "name":"La Apartada", 	"code":"23350"},
    {"id":439,"id_depto":14, "name":"Lorica",	"code":"23417"},
    {"id":440,"id_depto":14, "name":"Los Córdobas", 	"code":"23419"},
    {"id":441,"id_depto":14, "name":"Momil",	"code":"23464"},
    {"id":442,"id_depto":14, "name":"Montelíbano",	"code":"23466"},
    {"id":443,"id_depto":14, "name":"Moñitos",	"code":"23500"},
    {"id":444,"id_depto":14, "name":"Planeta Rica", 	"code":"23555"},
    {"id":445,"id_depto":14, "name":"Pueblo Nuevo", 	"code":"23570"},
    {"id":446,"id_depto":14, "name":"Puerto Escondido", 	"code":"23574"},
    {"id":447,"id_depto":14, "name":"Puerto Libertador", 	"code":"23580"},
    {"id":448,"id_depto":14, "name":"Purísima De La Concepción",	"code":"23586"},
    {"id":449,"id_depto":14, "name":"Sahagún",	"code":"23660"},
    {"id":450,"id_depto":14, "name":"San Andrés De Sotavento",	"code":"23670"},
    {"id":451,"id_depto":14, "name":"San Antero", 	"code":"23672"},
    {"id":452,"id_depto":14, "name":"San Bernardo Del Viento",	"code":"23675"},
    {"id":453,"id_depto":14, "name":"San Carlos", 	"code":"23678"},
    {"id":454,"id_depto":14, "name":"San José De Uré",	"code":"23682"},
    {"id":455,"id_depto":14, "name":"San Pelayo", 	"code":"23686"},
    {"id":456,"id_depto":14, "name":"Tierralta",	"code":"23807"},
    {"id":457,"id_depto":14, "name":"Tuchín",	"code":"23815"},
    {"id":458,"id_depto":14, "name":"Valencia",	"code":"23855"},
    {"id":459,"id_depto":15, "name":"Agua De Dios",	"code":"25001"},
    {"id":460,"id_depto":15, "name":"Albán",	"code":"25019"},
    {"id":461,"id_depto":15, "name":"Anapoima",	"code":"25035"},
    {"id":462,"id_depto":15, "name":"Anolaima",	"code":"25040"},
    {"id":463,"id_depto":15, "name":"Arbeláez",	"code":"25053"},
    {"id":464,"id_depto":15, "name":"Beltrán",	"code":"25086"},
    {"id":465,"id_depto":15, "name":"Bituima",	"code":"25095"},
    {"id":466,"id_depto":15, "name":"Bojacá",	"code":"25099"},
    {"id":467,"id_depto":15, "name":"Cabrera",	"code":"25120"},
    {"id":468,"id_depto":15, "name":"Cachipay",	"code":"25123"},
    {"id":469,"id_depto":15, "name":"Cajicá",	"code":"25126"},
    {"id":470,"id_depto":15, "name":"Caparrapí",	"code":"25148"},
    {"id":471,"id_depto":15, "name":"Cáqueza",	"code":"25151"},
    {"id":472,"id_depto":15, "name":"Carmen De Carupa",	"code":"25154"},
    {"id":473,"id_depto":15, "name":"Chaguaní",	"code":"25168"},
    {"id":474,"id_depto":15, "name":"Chía",	"code":"25175"},
    {"id":475,"id_depto":15, "name":"Chipaque",	"code":"25178"},
    {"id":476,"id_depto":15, "name":"Choachí",	"code":"25181"},
    {"id":477,"id_depto":15, "name":"Chocontá",	"code":"25183"},
    {"id":478,"id_depto":15, "name":"Cogua",	"code":"25200"},
    {"id":479,"id_depto":15, "name":"Cota",	"code":"25214"},
    {"id":480,"id_depto":15, "name":"Cucunubá",	"code":"25224"},
    {"id":481,"id_depto":15, "name":"El Colegio", 	"code":"25245"},
    {"id":482,"id_depto":15, "name":"El Peñón", 	"code":"25258"},
    {"id":483,"id_depto":15, "name":"El Rosal", 	"code":"25260"},
    {"id":484,"id_depto":15, "name":"Facatativá",	"code":"25269"},
    {"id":485,"id_depto":15, "name":"Fómeque",	"code":"25279"},
    {"id":486,"id_depto":15, "name":"Fosca",	"code":"25281"},
    {"id":487,"id_depto":15, "name":"Funza",	"code":"25286"},
    {"id":488,"id_depto":15, "name":"Fúquene",	"code":"25288"},
    {"id":489,"id_depto":15, "name":"Fusagasugá",	"code":"25290"},
    {"id":490,"id_depto":15, "name":"Gachalá",	"code":"25293"},
    {"id":491,"id_depto":15, "name":"Gachancipá",	"code":"25295"},
    {"id":492,"id_depto":15, "name":"Gachetá",	"code":"25297"},
    {"id":493,"id_depto":15, "name":"Gama",	"code":"25299"},
    {"id":494,"id_depto":15, "name":"Girardot",	"code":"25307"},
    {"id":495,"id_depto":15, "name":"Granada",	"code":"25312"},
    {"id":496,"id_depto":15, "name":"Guachetá",	"code":"25317"},
    {"id":497,"id_depto":15, "name":"Guaduas",	"code":"25320"},
    {"id":498,"id_depto":15, "name":"Guasca",	"code":"25322"},
    {"id":499,"id_depto":15, "name":"Guataquí",	"code":"25324"},
    {"id":500,"id_depto":15, "name":"Guatavita",	"code":"25326"},
    {"id":501,"id_depto":15, "name":"Guayabal De Síquima",	"code":"25328"},
    {"id":502,"id_depto":15, "name":"Guayabetal",	"code":"25335"},
    {"id":503,"id_depto":15, "name":"Gutiérrez",	"code":"25339"},
    {"id":504,"id_depto":15, "name":"Jerusalén",	"code":"25368"},
    {"id":505,"id_depto":15, "name":"Junín",	"code":"25372"},
    {"id":506,"id_depto":15, "name":"La Calera", 	"code":"25377"},
    {"id":507,"id_depto":15, "name":"La Mesa", 	"code":"25386"},
    {"id":508,"id_depto":15, "name":"La Palma", 	"code":"25394"},
    {"id":509,"id_depto":15, "name":"La Peña", 	"code":"25398"},
    {"id":510,"id_depto":15, "name":"La Vega", 	"code":"25402"},
    {"id":511,"id_depto":15, "name":"Lenguazaque",	"code":"25407"},
    {"id":512,"id_depto":15, "name":"Machetá",	"code":"25426"},
    {"id":513,"id_depto":15, "name":"Madrid",	"code":"25430"},
    {"id":514,"id_depto":15, "name":"Manta",	"code":"25436"},
    {"id":515,"id_depto":15, "name":"Medina",	"code":"25438"},
    {"id":516,"id_depto":15, "name":"Mosquera",	"code":"25473"},
    {"id":517,"id_depto":15, "name":"Nariño",	"code":"25483"},
    {"id":518,"id_depto":15, "name":"Nemocón",	"code":"25486"},
    {"id":519,"id_depto":15, "name":"Nilo",	"code":"25488"},
    {"id":520,"id_depto":15, "name":"Nimaima",	"code":"25489"},
    {"id":521,"id_depto":15, "name":"Nocaima",	"code":"25491"},
    {"id":522,"id_depto":15, "name":"Venecia",	"code":"25506"},
    {"id":523,"id_depto":15, "name":"Pacho",	"code":"25513"},
    {"id":524,"id_depto":15, "name":"Paime",	"code":"25518"},
    {"id":525,"id_depto":15, "name":"Pandi",	"code":"25524"},
    {"id":526,"id_depto":15, "name":"Paratebueno",	"code":"25530"},
    {"id":527,"id_depto":15, "name":"Pasca",	"code":"25535"},
    {"id":528,"id_depto":15, "name":"Puerto Salgar", 	"code":"25572"},
    {"id":529,"id_depto":15, "name":"Pulí",	"code":"25580"},
    {"id":530,"id_depto":15, "name":"Quebradanegra",	"code":"25592"},
    {"id":531,"id_depto":15, "name":"Quetame",	"code":"25594"},
    {"id":532,"id_depto":15, "name":"Quipile",	"code":"25596"},
    {"id":533,"id_depto":15, "name":"Apulo",	"code":"25599"},
    {"id":534,"id_depto":15, "name":"Ricaurte",	"code":"25612"},
    {"id":535,"id_depto":15, "name":"San Antonio Del Tequendama",	"code":"25645"},
    {"id":536,"id_depto":15, "name":"San Bernardo", 	"code":"25649"},
    {"id":537,"id_depto":15, "name":"San Cayetano", 	"code":"25653"},
    {"id":538,"id_depto":15, "name":"San Francisco", 	"code":"25658"},
    {"id":539,"id_depto":15, "name":"San Juan De Rioseco",	"code":"25662"},
    {"id":540,"id_depto":15, "name":"Sasaima",	"code":"25718"},
    {"id":541,"id_depto":15, "name":"Sesquilé",	"code":"25736"},
    {"id":542,"id_depto":15, "name":"Sibaté",	"code":"25740"},
    {"id":543,"id_depto":15, "name":"Silvania",	"code":"25743"},
    {"id":544,"id_depto":15, "name":"Simijaca",	"code":"25745"},
    {"id":545,"id_depto":15, "name":"Soacha",	"code":"25754"},
    {"id":546,"id_depto":15, "name":"Sopó",	"code":"25758"},
    {"id":547,"id_depto":15, "name":"Subachoque",	"code":"25769"},
    {"id":548,"id_depto":15, "name":"Suesca",	"code":"25772"},
    {"id":549,"id_depto":15, "name":"Supatá",	"code":"25777"},
    {"id":550,"id_depto":15, "name":"Susa",	"code":"25779"},
    {"id":551,"id_depto":15, "name":"Sutatausa",	"code":"25781"},
    {"id":552,"id_depto":15, "name":"Tabio",	"code":"25785"},
    {"id":553,"id_depto":15, "name":"Tausa",	"code":"25793"},
    {"id":554,"id_depto":15, "name":"Tena",	"code":"25797"},
    {"id":555,"id_depto":15, "name":"Tenjo",	"code":"25799"},
    {"id":556,"id_depto":15, "name":"Tibacuy",	"code":"25805"},
    {"id":557,"id_depto":15, "name":"Tibirita",	"code":"25807"},
    {"id":558,"id_depto":15, "name":"Tocaima",	"code":"25815"},
    {"id":559,"id_depto":15, "name":"Tocancipá",	"code":"25817"},
    {"id":560,"id_depto":15, "name":"Topaipí",	"code":"25823"},
    {"id":561,"id_depto":15, "name":"Ubalá",	"code":"25839"},
    {"id":562,"id_depto":15, "name":"Ubaque",	"code":"25841"},
    {"id":563,"id_depto":15, "name":"Villa De San Diego De Ubaté",	"code":"25843"},
    {"id":564,"id_depto":15, "name":"Une",	"code":"25845"},
    {"id":565,"id_depto":15, "name":"Útica",	"code":"25851"},
    {"id":566,"id_depto":15, "name":"Vergara",	"code":"25862"},
    {"id":567,"id_depto":15, "name":"Vianí",	"code":"25867"},
    {"id":568,"id_depto":15, "name":"Villagómez",	"code":"25871"},
    {"id":569,"id_depto":15, "name":"Villapinzón",	"code":"25873"},
    {"id":570,"id_depto":15, "name":"Villeta",	"code":"25875"},
    {"id":571,"id_depto":15, "name":"Viotá",	"code":"25878"},
    {"id":572,"id_depto":15, "name":"Yacopí",	"code":"25885"},
    {"id":573,"id_depto":15, "name":"Zipacón",	"code":"25898"},
    {"id":574,"id_depto":15, "name":"Zipaquirá",	"code":"25899"},
    {"id":575,"id_depto":13, "name":"Quibdó",	"code":"27001"},
    {"id":576,"id_depto":13, "name":"Acandí",	"code":"27006"},
    {"id":577,"id_depto":13, "name":"Alto Baudó", 	"code":"27025"},
    {"id":578,"id_depto":13, "name":"Atrato",	"code":"27050"},
    {"id":579,"id_depto":13, "name":"Bagadó",	"code":"27073"},
    {"id":580,"id_depto":13, "name":"Bahía Solano", 	"code":"27075"},
    {"id":581,"id_depto":13, "name":"Bajo Baudó", 	"code":"27077"},
    {"id":582,"id_depto":13, "name":"Bojayá",	"code":"27099"},
    {"id":583,"id_depto":13, "name":"El Cantón Del San Pablo", 	"code":"27135"},
    {"id":584,"id_depto":13, "name":"Carmen Del Darién",	"code":"27150"},
    {"id":585,"id_depto":13, "name":"Cértegui",	"code":"27160"},
    {"id":586,"id_depto":13, "name":"Condoto",	"code":"27205"},
    {"id":587,"id_depto":13, "name":"El Carmen De Atrato",	"code":"27245"},
    {"id":588,"id_depto":13, "name":"El Litoral Del San Juan", 	"code":"27250"},
    {"id":589,"id_depto":13, "name":"Istmina",	"code":"27361"},
    {"id":590,"id_depto":13, "name":"Juradó",	"code":"27372"},
    {"id":591,"id_depto":13, "name":"Lloró",	"code":"27413"},
    {"id":592,"id_depto":13, "name":"Medio Atrato", 	"code":"27425"},
    {"id":593,"id_depto":13, "name":"Medio Baudó", 	"code":"27430"},
    {"id":594,"id_depto":13, "name":"Medio San Juan",	"code":"27450"},
    {"id":595,"id_depto":13, "name":"Nóvita",	"code":"27491"},
    {"id":596,"id_depto":13, "name":"Nuquí",	"code":"27495"},
    {"id":597,"id_depto":13, "name":"Río Iró", 	"code":"27580"},
    {"id":598,"id_depto":13, "name":"Río Quito", 	"code":"27600"},
    {"id":599,"id_depto":13, "name":"Riosucio",	"code":"27615"},
    {"id":600,"id_depto":13, "name":"San José Del Palmar",	"code":"27660"},
    {"id":601,"id_depto":13, "name":"Sipí",	"code":"27745"},
    {"id":602,"id_depto":13, "name":"Tadó",	"code":"27787"},
    {"id":603,"id_depto":13, "name":"Unguía",	"code":"27800"},
    {"id":604,"id_depto":13, "name":"Unión Panamericana", 	"code":"27810"},
    {"id":605,"id_depto":18, "name":"Neiva",	"code":"41001"},
    {"id":606,"id_depto":18, "name":"Acevedo",	"code":"41006"},
    {"id":607,"id_depto":18, "name":"Agrado",	"code":"41013"},
    {"id":608,"id_depto":18, "name":"Aipe",	"code":"41016"},
    {"id":609,"id_depto":18, "name":"Algeciras",	"code":"41020"},
    {"id":610,"id_depto":18, "name":"Altamira",	"code":"41026"},
    {"id":611,"id_depto":18, "name":"Baraya",	"code":"41078"},
    {"id":612,"id_depto":18, "name":"Campoalegre",	"code":"41132"},
    {"id":613,"id_depto":18, "name":"Colombia",	"code":"41206"},
    {"id":614,"id_depto":18, "name":"Elías",	"code":"41244"},
    {"id":615,"id_depto":18, "name":"Garzón",	"code":"41298"},
    {"id":616,"id_depto":18, "name":"Gigante",	"code":"41306"},
    {"id":617,"id_depto":18, "name":"Guadalupe",	"code":"41319"},
    {"id":618,"id_depto":18, "name":"Hobo",	"code":"41349"},
    {"id":619,"id_depto":18, "name":"Íquira",	"code":"41357"},
    {"id":620,"id_depto":18, "name":"Isnos",	"code":"41359"},
    {"id":621,"id_depto":18, "name":"La Argentina", 	"code":"41378"},
    {"id":622,"id_depto":18, "name":"La Plata", 	"code":"41396"},
    {"id":623,"id_depto":18, "name":"Nátaga",	"code":"41483"},
    {"id":624,"id_depto":18, "name":"Oporapa",	"code":"41503"},
    {"id":625,"id_depto":18, "name":"Paicol",	"code":"41518"},
    {"id":626,"id_depto":18, "name":"Palermo",	"code":"41524"},
    {"id":627,"id_depto":18, "name":"Palestina",	"code":"41530"},
    {"id":628,"id_depto":18, "name":"Pital",	"code":"41548"},
    {"id":629,"id_depto":18, "name":"Pitalito",	"code":"41551"},
    {"id":630,"id_depto":18, "name":"Rivera",	"code":"41615"},
    {"id":631,"id_depto":18, "name":"Saladoblanco",	"code":"41660"},
    {"id":632,"id_depto":18, "name":"San Agustín", 	"code":"41668"},
    {"id":633,"id_depto":18, "name":"Santa María", 	"code":"41676"},
    {"id":634,"id_depto":18, "name":"Suaza",	"code":"41770"},
    {"id":635,"id_depto":18, "name":"Tarqui",	"code":"41791"},
    {"id":636,"id_depto":18, "name":"Tesalia",	"code":"41797"},
    {"id":637,"id_depto":18, "name":"Tello",	"code":"41799"},
    {"id":638,"id_depto":18, "name":"Teruel",	"code":"41801"},
    {"id":639,"id_depto":18, "name":"Timaná",	"code":"41807"},
    {"id":640,"id_depto":18, "name":"Villavieja",	"code":"41872"},
    {"id":641,"id_depto":18, "name":"Yaguará",	"code":"41885"},
    {"id":642,"id_depto":19, "name":"Riohacha",	"code":"44001"},
    {"id":643,"id_depto":19, "name":"Albania",	"code":"44035"},
    {"id":644,"id_depto":19, "name":"Barrancas",	"code":"44078"},
    {"id":645,"id_depto":19, "name":"Dibulla",	"code":"44090"},
    {"id":646,"id_depto":19, "name":"Distracción",	"code":"44098"},
    {"id":647,"id_depto":19, "name":"El Molino", 	"code":"44110"},
    {"id":648,"id_depto":19, "name":"Fonseca",	"code":"44279"},
    {"id":649,"id_depto":19, "name":"Hatonuevo",	"code":"44378"},
    {"id":650,"id_depto":19, "name":"La Jagua Del Pilar",	"code":"44420"},
    {"id":651,"id_depto":19, "name":"Maicao",	"code":"44430"},
    {"id":652,"id_depto":19, "name":"Manaure",	"code":"44560"},
    {"id":653,"id_depto":19, "name":"San Juan Del Cesar",	"code":"44650"},
    {"id":654,"id_depto":19, "name":"Uribia",	"code":"44847"},
    {"id":655,"id_depto":19, "name":"Urumita",	"code":"44855"},
    {"id":656,"id_depto":19, "name":"Villanueva",	"code":"44874"},
    {"id":657,"id_depto":20, "name":"Santa Marta", 	"code":"47001"},
    {"id":658,"id_depto":20, "name":"Algarrobo",	"code":"47030"},
    {"id":659,"id_depto":20, "name":"Aracataca",	"code":"47053"},
    {"id":660,"id_depto":20, "name":"Ariguaní",	"code":"47058"},
    {"id":661,"id_depto":20, "name":"Cerro De San Antonio",	"code":"47161"},
    {"id":662,"id_depto":20, "name":"Chivolo",	"code":"47170"},
    {"id":663,"id_depto":20, "name":"Ciénaga",	"code":"47189"},
    {"id":664,"id_depto":20, "name":"Concordia",	"code":"47205"},
    {"id":665,"id_depto":20, "name":"El Banco", 	"code":"47245"},
    {"id":666,"id_depto":20, "name":"El Piñón", 	"code":"47258"},
    {"id":667,"id_depto":20, "name":"El Retén", 	"code":"47268"},
    {"id":668,"id_depto":20, "name":"Fundación",	"code":"47288"},
    {"id":669,"id_depto":20, "name":"Guamal",	"code":"47318"},
    {"id":670,"id_depto":20, "name":"Nueva Granada", 	"code":"47460"},
    {"id":671,"id_depto":20, "name":"Pedraza",	"code":"47541"},
    {"id":672,"id_depto":20, "name":"Pijiño Del Carmen",	"code":"47545"},
    {"id":673,"id_depto":20, "name":"Pivijay",	"code":"47551"},
    {"id":674,"id_depto":20, "name":"Plato",	"code":"47555"},
    {"id":675,"id_depto":20, "name":"Puebloviejo",	"code":"47570"},
    {"id":676,"id_depto":20, "name":"Remolino",	"code":"47605"},
    {"id":677,"id_depto":20, "name":"Sabanas De San Ángel",	"code":"47660"},
    {"id":678,"id_depto":20, "name":"Salamina",	"code":"47675"},
    {"id":679,"id_depto":20, "name":"San Sebastián De Buenavista",	"code":"47692"},
    {"id":680,"id_depto":20, "name":"San Zenón", 	"code":"47703"},
    {"id":681,"id_depto":20, "name":"Santa Ana", 	"code":"47707"},
    {"id":682,"id_depto":20, "name":"Santa Bárbara De Pinto",	"code":"47720"},
    {"id":683,"id_depto":20, "name":"Sitionuevo",	"code":"47745"},
    {"id":684,"id_depto":20, "name":"Tenerife",	"code":"47798"},
    {"id":685,"id_depto":20, "name":"Zapayán",	"code":"47960"},
    {"id":686,"id_depto":20, "name":"Zona Bananera", 	"code":"47980"},
    {"id":687,"id_depto":21, "name":"Villavicencio",	"code":"50001"},
    {"id":688,"id_depto":21, "name":"Acacías",	"code":"50006"},
    {"id":689,"id_depto":21, "name":"Barranca De Upía",	"code":"50110"},
    {"id":690,"id_depto":21, "name":"Cabuyaro",	"code":"50124"},
    {"id":691,"id_depto":21, "name":"Castilla La Nueva",	"code":"50150"},
    {"id":692,"id_depto":21, "name":"Cubarral",	"code":"50223"},
    {"id":693,"id_depto":21, "name":"Cumaral",	"code":"50226"},
    {"id":694,"id_depto":21, "name":"El Calvario", 	"code":"50245"},
    {"id":695,"id_depto":21, "name":"El Castillo", 	"code":"50251"},
    {"id":696,"id_depto":21, "name":"El Dorado", 	"code":"50270"},
    {"id":697,"id_depto":21, "name":"Fuente De Oro",	"code":"50287"},
    {"id":698,"id_depto":21, "name":"Granada",	"code":"50313"},
    {"id":699,"id_depto":21, "name":"Guamal",	"code":"50318"},
    {"id":700,"id_depto":21, "name":"Mapiripán",	"code":"50325"},
    {"id":701,"id_depto":21, "name":"Mesetas",	"code":"50330"},
    {"id":702,"id_depto":21, "name":"La Macarena", 	"code":"50350"},
    {"id":703,"id_depto":21, "name":"Uribe",	"code":"50370"},
    {"id":704,"id_depto":21, "name":"Lejanías",	"code":"50400"},
    {"id":705,"id_depto":21, "name":"Puerto Concordia", 	"code":"50450"},
    {"id":706,"id_depto":21, "name":"Puerto Gaitán", 	"code":"50568"},
    {"id":707,"id_depto":21, "name":"Puerto López", 	"code":"50573"},
    {"id":708,"id_depto":21, "name":"Puerto Lleras", 	"code":"50577"},
    {"id":709,"id_depto":21, "name":"Puerto Rico", 	"code":"50590"},
    {"id":710,"id_depto":21, "name":"Restrepo",	"code":"50606"},
    {"id":711,"id_depto":21, "name":"San Carlos De Guaroa",	"code":"50680"},
    {"id":712,"id_depto":21, "name":"San Juan De Arama",	"code":"50683"},
    {"id":713,"id_depto":21, "name":"San Juanito", 	"code":"50686"},
    {"id":714,"id_depto":21, "name":"San Martín", 	"code":"50689"},
    {"id":715,"id_depto":21, "name":"Vistahermosa",	"code":"50711"},
    {"id":716,"id_depto":22, "name":"Pasto",	"code":"52001"},
    {"id":717,"id_depto":22, "name":"Albán",	"code":"52019"},
    {"id":718,"id_depto":22, "name":"Aldana",	"code":"52022"},
    {"id":719,"id_depto":22, "name":"Ancuyá",	"code":"52036"},
    {"id":720,"id_depto":22, "name":"Arboleda",	"code":"52051"},
    {"id":721,"id_depto":22, "name":"Barbacoas",	"code":"52079"},
    {"id":722,"id_depto":22, "name":"Belén",	"code":"52083"},
    {"id":723,"id_depto":22, "name":"Buesaco",	"code":"52110"},
    {"id":724,"id_depto":22, "name":"Colón",	"code":"52203"},
    {"id":725,"id_depto":22, "name":"Consacá",	"code":"52207"},
    {"id":726,"id_depto":22, "name":"Contadero",	"code":"52210"},
    {"id":727,"id_depto":22, "name":"Córdoba",	"code":"52215"},
    {"id":728,"id_depto":22, "name":"Cuaspúd",	"code":"52224"},
    {"id":729,"id_depto":22, "name":"Cumbal",	"code":"52227"},
    {"id":730,"id_depto":22, "name":"Cumbitara",	"code":"52233"},
    {"id":731,"id_depto":22, "name":"Chachagüí",	"code":"52240"},
    {"id":732,"id_depto":22, "name":"El Charco", 	"code":"52250"},
    {"id":733,"id_depto":22, "name":"El Peñol", 	"code":"52254"},
    {"id":734,"id_depto":22, "name":"El Rosario", 	"code":"52256"},
    {"id":735,"id_depto":22, "name":"El Tablón De Gómez",	"code":"52258"},
    {"id":736,"id_depto":22, "name":"El Tambo", 	"code":"52260"},
    {"id":737,"id_depto":22, "name":"Funes",	"code":"52287"},
    {"id":738,"id_depto":22, "name":"Guachucal",	"code":"52317"},
    {"id":739,"id_depto":22, "name":"Guaitarilla",	"code":"52320"},
    {"id":740,"id_depto":22, "name":"Gualmatán",	"code":"52323"},
    {"id":741,"id_depto":22, "name":"Iles",	"code":"52352"},
    {"id":742,"id_depto":22, "name":"Imués",	"code":"52354"},
    {"id":743,"id_depto":22, "name":"Ipiales",	"code":"52356"},
    {"id":744,"id_depto":22, "name":"La Cruz", 	"code":"52378"},
    {"id":745,"id_depto":22, "name":"La Florida", 	"code":"52381"},
    {"id":746,"id_depto":22, "name":"La Llanada", 	"code":"52385"},
    {"id":747,"id_depto":22, "name":"La Tola", 	"code":"52390"},
    {"id":748,"id_depto":22, "name":"La Unión", 	"code":"52399"},
    {"id":749,"id_depto":22, "name":"Leiva",	"code":"52405"},
    {"id":750,"id_depto":22, "name":"Linares",	"code":"52411"},
    {"id":751,"id_depto":22, "name":"Los Andes", 	"code":"52418"},
    {"id":752,"id_depto":22, "name":"Magüí",	"code":"52427"},
    {"id":753,"id_depto":22, "name":"Mallama",	"code":"52435"},
    {"id":754,"id_depto":22, "name":"Mosquera",	"code":"52473"},
    {"id":755,"id_depto":22, "name":"Nariño",	"code":"52480"},
    {"id":756,"id_depto":22, "name":"Olaya Herrera", 	"code":"52490"},
    {"id":757,"id_depto":22, "name":"Ospina",	"code":"52506"},
    {"id":758,"id_depto":22, "name":"Francisco Pizarro", 	"code":"52520"},
    {"id":759,"id_depto":22, "name":"Policarpa",	"code":"52540"},
    {"id":760,"id_depto":22, "name":"Potosí",	"code":"52560"},
    {"id":761,"id_depto":22, "name":"Providencia",	"code":"52565"},
    {"id":762,"id_depto":22, "name":"Puerres",	"code":"52573"},
    {"id":763,"id_depto":22, "name":"Pupiales",	"code":"52585"},
    {"id":764,"id_depto":22, "name":"Ricaurte",	"code":"52612"},
    {"id":765,"id_depto":22, "name":"Roberto Payán", 	"code":"52621"},
    {"id":766,"id_depto":22, "name":"Samaniego",	"code":"52678"},
    {"id":767,"id_depto":22, "name":"Sandoná",	"code":"52683"},
    {"id":768,"id_depto":22, "name":"San Bernardo", 	"code":"52685"},
    {"id":769,"id_depto":22, "name":"San Lorenzo", 	"code":"52687"},
    {"id":770,"id_depto":22, "name":"San Pablo", 	"code":"52693"},
    {"id":771,"id_depto":22, "name":"San Pedro De Cartago",	"code":"52694"},
    {"id":772,"id_depto":22, "name":"Santa Bárbara", 	"code":"52696"},
    {"id":773,"id_depto":22, "name":"Santacruz",	"code":"52699"},
    {"id":774,"id_depto":22, "name":"Sapuyes",	"code":"52720"},
    {"id":775,"id_depto":22, "name":"Taminango",	"code":"52786"},
    {"id":776,"id_depto":22, "name":"Tangua",	"code":"52788"},
    {"id":777,"id_depto":22, "name":"San Andrés De Tumaco",	"code":"52835"},
    {"id":778,"id_depto":22, "name":"Túquerres",	"code":"52838"},
    {"id":779,"id_depto":22, "name":"Yacuanquer",	"code":"52885"},
    {"id":780,"id_depto":23, "name":"San José De Cúcuta",	"code":"54001"},
    {"id":781,"id_depto":23, "name":"Ábrego",	"code":"54003"},
    {"id":782,"id_depto":23, "name":"Arboledas",	"code":"54051"},
    {"id":783,"id_depto":23, "name":"Bochalema",	"code":"54099"},
    {"id":784,"id_depto":23, "name":"Bucarasica",	"code":"54109"},
    {"id":785,"id_depto":23, "name":"Cácota",	"code":"54125"},
    {"id":786,"id_depto":23, "name":"Cáchira",	"code":"54128"},
    {"id":787,"id_depto":23, "name":"Chinácota",	"code":"54172"},
    {"id":788,"id_depto":23, "name":"Chitagá",	"code":"54174"},
    {"id":789,"id_depto":23, "name":"Convención",	"code":"54206"},
    {"id":790,"id_depto":23, "name":"Cucutilla",	"code":"54223"},
    {"id":791,"id_depto":23, "name":"Durania",	"code":"54239"},
    {"id":792,"id_depto":23, "name":"El Carmen", 	"code":"54245"},
    {"id":793,"id_depto":23, "name":"El Tarra", 	"code":"54250"},
    {"id":794,"id_depto":23, "name":"El Zulia", 	"code":"54261"},
    {"id":795,"id_depto":23, "name":"Gramalote",	"code":"54313"},
    {"id":796,"id_depto":23, "name":"Hacarí",	"code":"54344"},
    {"id":797,"id_depto":23, "name":"Herrán",	"code":"54347"},
    {"id":798,"id_depto":23, "name":"Labateca",	"code":"54377"},
    {"id":799,"id_depto":23, "name":"La Esperanza", 	"code":"54385"},
    {"id":800,"id_depto":23, "name":"La Playa", 	"code":"54398"},
    {"id":801,"id_depto":23, "name":"Los Patios", 	"code":"54405"},
    {"id":802,"id_depto":23, "name":"Lourdes",	"code":"54418"},
    {"id":803,"id_depto":23, "name":"Mutiscua",	"code":"54480"},
    {"id":804,"id_depto":23, "name":"Ocaña",	"code":"54498"},
    {"id":805,"id_depto":23, "name":"Pamplona",	"code":"54518"},
    {"id":806,"id_depto":23, "name":"Pamplonita",	"code":"54520"},
    {"id":807,"id_depto":23, "name":"Puerto Santander", 	"code":"54553"},
    {"id":808,"id_depto":23, "name":"Ragonvalia",	"code":"54599"},
    {"id":809,"id_depto":23, "name":"Salazar",	"code":"54660"},
    {"id":810,"id_depto":23, "name":"San Calixto", 	"code":"54670"},
    {"id":811,"id_depto":23, "name":"San Cayetano", 	"code":"54673"},
    {"id":812,"id_depto":23, "name":"Santiago",	"code":"54680"},
    {"id":813,"id_depto":23, "name":"Sardinata",	"code":"54720"},
    {"id":814,"id_depto":23, "name":"Silos",	"code":"54743"},
    {"id":815,"id_depto":23, "name":"Teorama",	"code":"54800"},
    {"id":816,"id_depto":23, "name":"Tibú",	"code":"54810"},
    {"id":817,"id_depto":23, "name":"Toledo",	"code":"54820"},
    {"id":818,"id_depto":23, "name":"Villa Caro", 	"code":"54871"},
    {"id":819,"id_depto":23, "name":"Villa Del Rosario",	"code":"54874"},
    {"id":820,"id_depto":25, "name":"Armenia",	"code":"63001"},
    {"id":821,"id_depto":25, "name":"Buenavista",	"code":"63111"},
    {"id":822,"id_depto":25, "name":"Calarcá",	"code":"63130"},
    {"id":823,"id_depto":25, "name":"Circasia",	"code":"63190"},
    {"id":824,"id_depto":25, "name":"Córdoba",	"code":"63212"},
    {"id":825,"id_depto":25, "name":"Filandia",	"code":"63272"},
    {"id":826,"id_depto":25, "name":"Génova",	"code":"63302"},
    {"id":827,"id_depto":25, "name":"La Tebaida", 	"code":"63401"},
    {"id":828,"id_depto":25, "name":"Montenegro",	"code":"63470"},
    {"id":829,"id_depto":25, "name":"Pijao",	"code":"63548"},
    {"id":830,"id_depto":25, "name":"Quimbaya",	"code":"63594"},
    {"id":831,"id_depto":25, "name":"Salento",	"code":"63690"},
    {"id":832,"id_depto":26, "name":"Pereira",	"code":"66001"},
    {"id":833,"id_depto":26, "name":"Apía",	"code":"66045"},
    {"id":834,"id_depto":26, "name":"Balboa",	"code":"66075"},
    {"id":835,"id_depto":26, "name":"Belén De Umbría",	"code":"66088"},
    {"id":836,"id_depto":26, "name":"Dosquebradas",	"code":"66170"},
    {"id":837,"id_depto":26, "name":"Guática",	"code":"66318"},
    {"id":838,"id_depto":26, "name":"La Celia", 	"code":"66383"},
    {"id":839,"id_depto":26, "name":"La Virginia", 	"code":"66400"},
    {"id":840,"id_depto":26, "name":"Marsella",	"code":"66440"},
    {"id":841,"id_depto":26, "name":"Mistrató",	"code":"66456"},
    {"id":842,"id_depto":26, "name":"Pueblo Rico", 	"code":"66572"},
    {"id":843,"id_depto":26, "name":"Quinchía",	"code":"66594"},
    {"id":844,"id_depto":26, "name":"Santa Rosa De Cabal",	"code":"66682"},
    {"id":845,"id_depto":26, "name":"Santuario",	"code":"66687"},
    {"id":846,"id_depto":28, "name":"Bucaramanga",	"code":"68001"},
    {"id":847,"id_depto":28, "name":"Aguada",	"code":"68013"},
    {"id":848,"id_depto":28, "name":"Albania",	"code":"68020"},
    {"id":849,"id_depto":28, "name":"Aratoca",	"code":"68051"},
    {"id":850,"id_depto":28, "name":"Barbosa",	"code":"68077"},
    {"id":851,"id_depto":28, "name":"Barichara",	"code":"68079"},
    {"id":852,"id_depto":28, "name":"Barrancabermeja",	"code":"68081"},
    {"id":853,"id_depto":28, "name":"Betulia",	"code":"68092"},
    {"id":854,"id_depto":28, "name":"Bolívar",	"code":"68101"},
    {"id":855,"id_depto":28, "name":"Cabrera",	"code":"68121"},
    {"id":856,"id_depto":28, "name":"California",	"code":"68132"},
    {"id":857,"id_depto":28, "name":"Capitanejo",	"code":"68147"},
    {"id":858,"id_depto":28, "name":"Carcasí",	"code":"68152"},
    {"id":859,"id_depto":28, "name":"Cepitá",	"code":"68160"},
    {"id":860,"id_depto":28, "name":"Cerrito",	"code":"68162"},
    {"id":861,"id_depto":28, "name":"Charalá",	"code":"68167"},
    {"id":862,"id_depto":28, "name":"Charta",	"code":"68169"},
    {"id":863,"id_depto":28, "name":"Chima",	"code":"68176"},
    {"id":864,"id_depto":28, "name":"Chipatá",	"code":"68179"},
    {"id":865,"id_depto":28, "name":"Cimitarra",	"code":"68190"},
    {"id":866,"id_depto":28, "name":"Concepción",	"code":"68207"},
    {"id":867,"id_depto":28, "name":"Confines",	"code":"68209"},
    {"id":868,"id_depto":28, "name":"Contratación",	"code":"68211"},
    {"id":869,"id_depto":28, "name":"Coromoro",	"code":"68217"},
    {"id":870,"id_depto":28, "name":"Curití",	"code":"68229"},
    {"id":871,"id_depto":28, "name":"El Carmen De Chucurí",	"code":"68235"},
    {"id":872,"id_depto":28, "name":"El Guacamayo", 	"code":"68245"},
    {"id":873,"id_depto":28, "name":"El Peñón", 	"code":"68250"},
    {"id":874,"id_depto":28, "name":"El Playón", 	"code":"68255"},
    {"id":875,"id_depto":28, "name":"Encino",	"code":"68264"},
    {"id":876,"id_depto":28, "name":"Enciso",	"code":"68266"},
    {"id":877,"id_depto":28, "name":"Florián",	"code":"68271"},
    {"id":878,"id_depto":28, "name":"Floridablanca",	"code":"68276"},
    {"id":879,"id_depto":28, "name":"Galán",	"code":"68296"},
    {"id":880,"id_depto":28, "name":"Gámbita",	"code":"68298"},
    {"id":881,"id_depto":28, "name":"Girón",	"code":"68307"},
    {"id":882,"id_depto":28, "name":"Guaca",	"code":"68318"},
    {"id":883,"id_depto":28, "name":"Guadalupe",	"code":"68320"},
    {"id":884,"id_depto":28, "name":"Guapotá",	"code":"68322"},
    {"id":885,"id_depto":28, "name":"Guavatá",	"code":"68324"},
    {"id":886,"id_depto":28, "name":"Güepsa",	"code":"68327"},
    {"id":887,"id_depto":28, "name":"Hato",	"code":"68344"},
    {"id":888,"id_depto":28, "name":"Jesús María", 	"code":"68368"},
    {"id":889,"id_depto":28, "name":"Jordán",	"code":"68370"},
    {"id":890,"id_depto":28, "name":"La Belleza", 	"code":"68377"},
    {"id":891,"id_depto":28, "name":"Landázuri",	"code":"68385"},
    {"id":892,"id_depto":28, "name":"La Paz", 	"code":"68397"},
    {"id":893,"id_depto":28, "name":"Lebrija",	"code":"68406"},
    {"id":894,"id_depto":28, "name":"Los Santos", 	"code":"68418"},
    {"id":895,"id_depto":28, "name":"Macaravita",	"code":"68425"},
    {"id":896,"id_depto":28, "name":"Málaga",	"code":"68432"},
    {"id":897,"id_depto":28, "name":"Matanza",	"code":"68444"},
    {"id":898,"id_depto":28, "name":"Mogotes",	"code":"68464"},
    {"id":899,"id_depto":28, "name":"Molagavita",	"code":"68468"},
    {"id":900,"id_depto":28, "name":"Ocamonte",	"code":"68498"},
    {"id":901,"id_depto":28, "name":"Oiba",	"code":"68500"},
    {"id":902,"id_depto":28, "name":"Onzaga",	"code":"68502"},
    {"id":903,"id_depto":28, "name":"Palmar",	"code":"68522"},
    {"id":904,"id_depto":28, "name":"Palmas Del Socorro",	"code":"68524"},
    {"id":905,"id_depto":28, "name":"Páramo",	"code":"68533"},
    {"id":906,"id_depto":28, "name":"Piedecuesta",	"code":"68547"},
    {"id":907,"id_depto":28, "name":"Pinchote",	"code":"68549"},
    {"id":908,"id_depto":28, "name":"Puente Nacional", 	"code":"68572"},
    {"id":909,"id_depto":28, "name":"Puerto Parra", 	"code":"68573"},
    {"id":910,"id_depto":28, "name":"Puerto Wilches", 	"code":"68575"},
    {"id":911,"id_depto":28, "name":"Rionegro",	"code":"68615"},
    {"id":912,"id_depto":28, "name":"Sabana De Torres",	"code":"68655"},
    {"id":913,"id_depto":28, "name":"San Andrés", 	"code":"68669"},
    {"id":914,"id_depto":28, "name":"San Benito", 	"code":"68673"},
    {"id":915,"id_depto":28, "name":"San Gil", 	"code":"68679"},
    {"id":916,"id_depto":28, "name":"San Joaquín", 	"code":"68682"},
    {"id":917,"id_depto":28, "name":"San José De Miranda",	"code":"68684"},
    {"id":918,"id_depto":28, "name":"San Miguel", 	"code":"68686"},
    {"id":919,"id_depto":28, "name":"San Vicente De Chucurí",	"code":"68689"},
    {"id":920,"id_depto":28, "name":"Santa Bárbara", 	"code":"68705"},
    {"id":921,"id_depto":28, "name":"Santa Helena Del Opón",	"code":"68720"},
    {"id":922,"id_depto":28, "name":"Simacota",	"code":"68745"},
    {"id":923,"id_depto":28, "name":"Socorro",	"code":"68755"},
    {"id":924,"id_depto":28, "name":"Suaita",	"code":"68770"},
    {"id":925,"id_depto":28, "name":"Sucre",	"code":"68773"},
    {"id":926,"id_depto":28, "name":"Suratá",	"code":"68780"},
    {"id":927,"id_depto":28, "name":"Tona",	"code":"68820"},
    {"id":928,"id_depto":28, "name":"Valle De San José",	"code":"68855"},
    {"id":929,"id_depto":28, "name":"Vélez",	"code":"68861"},
    {"id":930,"id_depto":28, "name":"Vetas",	"code":"68867"},
    {"id":931,"id_depto":28, "name":"Villanueva",	"code":"68872"},
    {"id":932,"id_depto":28, "name":"Zapatoca",	"code":"68895"},
    {"id":933,"id_depto":29, "name":"Sincelejo",	"code":"70001"},
    {"id":934,"id_depto":29, "name":"Buenavista",	"code":"70110"},
    {"id":935,"id_depto":29, "name":"Caimito",	"code":"70124"},
    {"id":936,"id_depto":29, "name":"Colosó",	"code":"70204"},
    {"id":937,"id_depto":29, "name":"Corozal",	"code":"70215"},
    {"id":938,"id_depto":29, "name":"Coveñas",	"code":"70221"},
    {"id":939,"id_depto":29, "name":"Chalán",	"code":"70230"},
    {"id":940,"id_depto":29, "name":"El Roble", 	"code":"70233"},
    {"id":941,"id_depto":29, "name":"Galeras",	"code":"70235"},
    {"id":942,"id_depto":29, "name":"Guaranda",	"code":"70265"},
    {"id":943,"id_depto":29, "name":"La Unión", 	"code":"70400"},
    {"id":944,"id_depto":29, "name":"Los Palmitos", 	"code":"70418"},
    {"id":945,"id_depto":29, "name":"Majagual",	"code":"70429"},
    {"id":946,"id_depto":29, "name":"Morroa",	"code":"70473"},
    {"id":947,"id_depto":29, "name":"Ovejas",	"code":"70508"},
    {"id":948,"id_depto":29, "name":"Palmito",	"code":"70523"},
    {"id":949,"id_depto":29, "name":"Sampués",	"code":"70670"},
    {"id":950,"id_depto":29, "name":"San Benito Abad",	"code":"70678"},
    {"id":951,"id_depto":29, "name":"San Juan De Betulia",	"code":"70702"},
    {"id":952,"id_depto":29, "name":"San Marcos", 	"code":"70708"},
    {"id":953,"id_depto":29, "name":"San Onofre", 	"code":"70713"},
    {"id":954,"id_depto":29, "name":"San Pedro", 	"code":"70717"},
    {"id":955,"id_depto":29, "name":"San Luis De Sincé",	"code":"70742"},
    {"id":956,"id_depto":29, "name":"Sucre",	"code":"70771"},
    {"id":957,"id_depto":29, "name":"Santiago De Tolú",	"code":"70820"},
    {"id":958,"id_depto":29, "name":"Tolú Viejo", 	"code":"70823"},
    {"id":959,"id_depto":30, "name":"Ibagué",	"code":"73001"},
    {"id":960,"id_depto":30, "name":"Alpujarra",	"code":"73024"},
    {"id":961,"id_depto":30, "name":"Alvarado",	"code":"73026"},
    {"id":962,"id_depto":30, "name":"Ambalema",	"code":"73030"},
    {"id":963,"id_depto":30, "name":"Anzoátegui",	"code":"73043"},
    {"id":964,"id_depto":30, "name":"Armero",	"code":"73055"},
    {"id":965,"id_depto":30, "name":"Ataco",	"code":"73067"},
    {"id":966,"id_depto":30, "name":"Cajamarca",	"code":"73124"},
    {"id":967,"id_depto":30, "name":"Carmen De Apicalá",	"code":"73148"},
    {"id":968,"id_depto":30, "name":"Casabianca",	"code":"73152"},
    {"id":969,"id_depto":30, "name":"Chaparral",	"code":"73168"},
    {"id":970,"id_depto":30, "name":"Coello",	"code":"73200"},
    {"id":971,"id_depto":30, "name":"Coyaima",	"code":"73217"},
    {"id":972,"id_depto":30, "name":"Cunday",	"code":"73226"},
    {"id":973,"id_depto":30, "name":"Dolores",	"code":"73236"},
    {"id":974,"id_depto":30, "name":"Espinal",	"code":"73268"},
    {"id":975,"id_depto":30, "name":"Falan",	"code":"73270"},
    {"id":976,"id_depto":30, "name":"Flandes",	"code":"73275"},
    {"id":977,"id_depto":30, "name":"Fresno",	"code":"73283"},
    {"id":978,"id_depto":30, "name":"Guamo",	"code":"73319"},
    {"id":979,"id_depto":30, "name":"Herveo",	"code":"73347"},
    {"id":980,"id_depto":30, "name":"Honda",	"code":"73349"},
    {"id":981,"id_depto":30, "name":"Icononzo",	"code":"73352"},
    {"id":982,"id_depto":30, "name":"Lérida",	"code":"73408"},
    {"id":983,"id_depto":30, "name":"Líbano",	"code":"73411"},
    {"id":984,"id_depto":30, "name":"San Sebastián De Mariquita",	"code":"73443"},
    {"id":985,"id_depto":30, "name":"Melgar",	"code":"73449"},
    {"id":986,"id_depto":30, "name":"Murillo",	"code":"73461"},
    {"id":987,"id_depto":30, "name":"Natagaima",	"code":"73483"},
    {"id":988,"id_depto":30, "name":"Ortega",	"code":"73504"},
    {"id":989,"id_depto":30, "name":"Palocabildo",	"code":"73520"},
    {"id":990,"id_depto":30, "name":"Piedras",	"code":"73547"},
    {"id":991,"id_depto":30, "name":"Planadas",	"code":"73555"},
    {"id":992,"id_depto":30, "name":"Prado",	"code":"73563"},
    {"id":993,"id_depto":30, "name":"Purificación",	"code":"73585"},
    {"id":994,"id_depto":30, "name":"Rioblanco",	"code":"73616"},
    {"id":995,"id_depto":30, "name":"Roncesvalles",	"code":"73622"},
    {"id":996,"id_depto":30, "name":"Rovira",	"code":"73624"},
    {"id":997,"id_depto":30, "name":"Saldaña",	"code":"73671"},
    {"id":998,"id_depto":30, "name":"San Antonio", 	"code":"73675"},
    {"id":999,"id_depto":30, "name":"San Luis", 	"code":"73678"},
    {"id":1000,"id_depto":30, "name":"Santa Isabel", 	"code":"73686"},
    {"id":1001,"id_depto":30, "name":"Suárez",	"code":"73770"},
    {"id":1002,"id_depto":30, "name":"Valle De San Juan",	"code":"73854"},
    {"id":1003,"id_depto":30, "name":"Venadillo",	"code":"73861"},
    {"id":1004,"id_depto":30, "name":"Villahermosa",	"code":"73870"},
    {"id":1005,"id_depto":30, "name":"Villarrica",	"code":"73873"},
    {"id":1006,"id_depto":31, "name":"Cali",	"code":"76001"},
    {"id":1007,"id_depto":31, "name":"Alcalá",	"code":"76020"},
    {"id":1008,"id_depto":31, "name":"Andalucía",	"code":"76036"},
    {"id":1009,"id_depto":31, "name":"Ansermanuevo",	"code":"76041"},
    {"id":1010,"id_depto":31, "name":"Argelia",	"code":"76054"},
    {"id":1011,"id_depto":31, "name":"Bolívar",	"code":"76100"},
    {"id":1012,"id_depto":31, "name":"Buenaventura",	"code":"76109"},
    {"id":1013,"id_depto":31, "name":"Guadalajara De Buga",	"code":"76111"},
    {"id":1014,"id_depto":31, "name":"Bugalagrande",	"code":"76113"},
    {"id":1015,"id_depto":31, "name":"Caicedonia",	"code":"76122"},
    {"id":1016,"id_depto":31, "name":"Calima",	"code":"76126"},
    {"id":1017,"id_depto":31, "name":"Candelaria",	"code":"76130"},
    {"id":1018,"id_depto":31, "name":"Cartago",	"code":"76147"},
    {"id":1019,"id_depto":31, "name":"Dagua",	"code":"76233"},
    {"id":1020,"id_depto":31, "name":"El Águila", 	"code":"76243"},
    {"id":1021,"id_depto":31, "name":"El Cairo", 	"code":"76246"},
    {"id":1022,"id_depto":31, "name":"El Cerrito", 	"code":"76248"},
    {"id":1023,"id_depto":31, "name":"El Dovio", 	"code":"76250"},
    {"id":1024,"id_depto":31, "name":"Florida",	"code":"76275"},
    {"id":1025,"id_depto":31, "name":"Ginebra",	"code":"76306"},
    {"id":1026,"id_depto":31, "name":"Guacarí",	"code":"76318"},
    {"id":1027,"id_depto":31, "name":"Jamundí",	"code":"76364"},
    {"id":1028,"id_depto":31, "name":"La Cumbre", 	"code":"76377"},
    {"id":1029,"id_depto":31, "name":"La Unión", 	"code":"76400"},
    {"id":1030,"id_depto":31, "name":"La Victoria", 	"code":"76403"},
    {"id":1031,"id_depto":31, "name":"Obando",	"code":"76497"},
    {"id":1032,"id_depto":31, "name":"Palmira",	"code":"76520"},
    {"id":1033,"id_depto":31, "name":"Pradera",	"code":"76563"},
    {"id":1034,"id_depto":31, "name":"Restrepo",	"code":"76606"},
    {"id":1035,"id_depto":31, "name":"Riofrío",	"code":"76616"},
    {"id":1036,"id_depto":31, "name":"Roldanillo",	"code":"76622"},
    {"id":1037,"id_depto":31, "name":"San Pedro", 	"code":"76670"},
    {"id":1038,"id_depto":31, "name":"Sevilla",	"code":"76736"},
    {"id":1039,"id_depto":31, "name":"Toro",	"code":"76823"},
    {"id":1040,"id_depto":31, "name":"Trujillo",	"code":"76828"},
    {"id":1041,"id_depto":31, "name":"Tuluá",	"code":"76834"},
    {"id":1042,"id_depto":31, "name":"Ulloa",	"code":"76845"},
    {"id":1043,"id_depto":31, "name":"Versalles",	"code":"76863"},
    {"id":1044,"id_depto":31, "name":"Vijes",	"code":"76869"},
    {"id":1045,"id_depto":31, "name":"Yotoco",	"code":"76890"},
    {"id":1046,"id_depto":31, "name":"Yumbo",	"code":"76892"},
    {"id":1047,"id_depto":31, "name":"Zarzal",	"code":"76895"},
    {"id":1048,"id_depto":3, "name":"Arauca",	"code":"81001"},
    {"id":1049,"id_depto":3, "name":"Arauquita",	"code":"81065"},
    {"id":1050,"id_depto":3, "name":"Cravo Norte", 	"code":"81220"},
    {"id":1051,"id_depto":3, "name":"Fortul",	"code":"81300"},
    {"id":1052,"id_depto":3, "name":"Puerto Rondón", 	"code":"81591"},
    {"id":1053,"id_depto":3, "name":"Saravena",	"code":"81736"},
    {"id":1054,"id_depto":3, "name":"Tame",	"code":"81794"},
    {"id":1055,"id_depto":10, "name":"Yopal",	"code":"85001"},
    {"id":1056,"id_depto":10, "name":"Aguazul",	"code":"85010"},
    {"id":1057,"id_depto":10, "name":"Chámeza",	"code":"85015"},
    {"id":1058,"id_depto":10, "name":"Hato Corozal", 	"code":"85125"},
    {"id":1059,"id_depto":10, "name":"La Salina", 	"code":"85136"},
    {"id":1060,"id_depto":10, "name":"Maní",	"code":"85139"},
    {"id":1061,"id_depto":10, "name":"Monterrey",	"code":"85162"},
    {"id":1062,"id_depto":10, "name":"Nunchía",	"code":"85225"},
    {"id":1063,"id_depto":10, "name":"Orocué",	"code":"85230"},
    {"id":1064,"id_depto":10, "name":"Paz De Ariporo",	"code":"85250"},
    {"id":1065,"id_depto":10, "name":"Pore",	"code":"85263"},
    {"id":1066,"id_depto":10, "name":"Recetor",	"code":"85279"},
    {"id":1067,"id_depto":10, "name":"Sabanalarga",	"code":"85300"},
    {"id":1068,"id_depto":10, "name":"Sácama",	"code":"85315"},
    {"id":1069,"id_depto":10, "name":"San Luis De Palenque",	"code":"85325"},
    {"id":1070,"id_depto":10, "name":"Támara",	"code":"85400"},
    {"id":1071,"id_depto":10, "name":"Tauramena",	"code":"85410"},
    {"id":1072,"id_depto":10, "name":"Trinidad",	"code":"85430"},
    {"id":1073,"id_depto":10, "name":"Villanueva",	"code":"85440"},
    {"id":1074,"id_depto":24, "name":"Mocoa",	"code":"86001"},
    {"id":1075,"id_depto":24, "name":"Colón",	"code":"86219"},
    {"id":1076,"id_depto":24, "name":"Orito",	"code":"86320"},
    {"id":1077,"id_depto":24, "name":"Puerto Asís", 	"code":"86568"},
    {"id":1078,"id_depto":24, "name":"Puerto Caicedo", 	"code":"86569"},
    {"id":1079,"id_depto":24, "name":"Puerto Guzmán", 	"code":"86571"},
    {"id":1080,"id_depto":24, "name":"Puerto Leguízamo", 	"code":"86573"},
    {"id":1081,"id_depto":24, "name":"Sibundoy",	"code":"86749"},
    {"id":1082,"id_depto":24, "name":"San Francisco", 	"code":"86755"},
    {"id":1083,"id_depto":24, "name":"San Miguel", 	"code":"86757"},
    {"id":1084,"id_depto":24, "name":"Santiago",	"code":"86760"},
    {"id":1085,"id_depto":24, "name":"Valle Del Guamuez",	"code":"86865"},
    {"id":1086,"id_depto":24, "name":"Villagarzón",	"code":"86885"},
    {"id":1087,"id_depto":27, "name":"San Andrés", 	"code":"88001"},
    {"id":1088,"id_depto":27, "name":"Providencia",	"code":"88564"},
    {"id":1089,"id_depto":1, "name":"Leticia",	"code":"91001"},
    {"id":1090,"id_depto":1, "name":"El Encanto", 	"code":"91263"},
    {"id":1091,"id_depto":1, "name":"La Chorrera", 	"code":"91405"},
    {"id":1092,"id_depto":1, "name":"La Pedrera", 	"code":"91407"},
    {"id":1093,"id_depto":1, "name":"La Victoria", 	"code":"91430"},
    {"id":1094,"id_depto":1, "name":"Mirití - Paraná",	"code":"91460"},
    {"id":1095,"id_depto":1, "name":"Puerto Alegría", 	"code":"91530"},
    {"id":1096,"id_depto":1, "name":"Puerto Arica", 	"code":"91536"},
    {"id":1097,"id_depto":1, "name":"Puerto Nariño", 	"code":"91540"},
    {"id":1098,"id_depto":1, "name":"Puerto Santander", 	"code":"91669"},
    {"id":1099,"id_depto":1, "name":"Tarapacá",	"code":"91798"},
    {"id":1100,"id_depto":16, "name":"Inírida",	"code":"94001"},
    {"id":1101,"id_depto":16, "name":"Barranco Minas", 	"code":"94343"},
    {"id":1102,"id_depto":16, "name":"Mapiripana",	"code":"94663"},
    {"id":1103,"id_depto":16, "name":"San Felipe", 	"code":"94883"},
    {"id":1104,"id_depto":16, "name":"Puerto Colombia", 	"code":"94884"},
    {"id":1105,"id_depto":16, "name":"La Guadalupe", 	"code":"94885"},
    {"id":1106,"id_depto":16, "name":"Cacahual",	"code":"94886"},
    {"id":1107,"id_depto":16, "name":"Pana Pana", 	"code":"94887"},
    {"id":1108,"id_depto":16, "name":"Morichal",	"code":"94888"},
    {"id":1109,"id_depto":17, "name":"San José Del Guaviare",	"code":"95001"},
    {"id":1110,"id_depto":17, "name":"Calamar",	"code":"95015"},
    {"id":1111,"id_depto":17, "name":"El Retorno", 	"code":"95025"},
    {"id":1112,"id_depto":17, "name":"Miraflores",	"code":"95200"},
    {"id":1113,"id_depto":32, "name":"Mitú",	"code":"97001"},
    {"id":1114,"id_depto":32, "name":"Carurú",	"code":"97161"},
    {"id":1115,"id_depto":32, "name":"Pacoa",	"code":"97511"},
    {"id":1116,"id_depto":32, "name":"Taraira",	"code":"97666"},
    {"id":1117,"id_depto":32, "name":"Papunahua",	"code":"97777"},
    {"id":1118,"id_depto":32, "name":"Yavaraté",	"code":"97889"},
    {"id":1119,"id_depto":33, "name":"Puerto Carreño", 	"code":"99001"},
    {"id":1120,"id_depto":33, "name":"La Primavera", 	"code":"99524"},
    {"id":1121,"id_depto":33, "name":"Santa Rosalía", 	"code":"99624"},
    {"id":1122,"id_depto":33, "name":"Cumaribo",	"code":"99773"}
];


